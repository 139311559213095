import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import store from "../../MobX";
import { observer } from "mobx-react-lite";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import StickyBox from "react-sticky-box";
import { getNameActiveFilter } from "../../funcs";
import FilterElement from "./FilterElement";
import { FilterElementRange } from "./FilterElementByType/FilterElementRange";

const { createSliderWithTooltip } = Slider;
const RangeYear = createSliderWithTooltip(Slider.Range);

const simpleBarMaxHeightMobile = "calc(100% - 60px)";

const POL_SORT = ["MAN", "WOMAN", "JUNIOR", "UNISEX"];

const POL_SORT_LOCALIZE = {
  MAN: "Мужское",
  WOMAN: "Женское",
  JUNIOR: "Детское",
  "": "Унисекс",
  UNISEX: "Унисекс",
  undefined: "Все",
};

const relatedFilter = {
  proizvoditelTehniki: [
    "_compatibilitiesFilter_year",
    "_compatibilitiesFilter_sharedModel",
    "_compatibilitiesFilter_model",
  ],
  _compatibilitiesFilter_year: [
    "_compatibilitiesFilter_sharedModel",
    "_compatibilitiesFilter_model",
  ],
  _compatibilitiesFilter_sharedModel: ["_compatibilitiesFilter_model"],
};

const getNameActiveFilterNew = (activeFilters) => (name, specName) => {
  if (name === "pol") return POL_SORT_LOCALIZE[activeFilters[name]];
  if (!activeFilters[name]) return [""];
  else if (Array.isArray(activeFilters[name])) {
    return activeFilters[name].map((elem, i) => {
      let nameVariation = elem;

      if (name === "perechenRazmerov")
        nameVariation = nameVariation.split("-")[1];
      else if (name === "spec") {
        let specFilterSplit = (nameVariation = nameVariation.split("-"));
        if (specFilterSplit[0] === specName) {
          nameVariation = specFilterSplit[1];
        } else nameVariation = null;
      }

      return nameVariation;
    });
  } else {
    let nameVariation = activeFilters[name];

    if (name === "god")
      nameVariation = `20${activeFilters[name].$gte} - 20${activeFilters[name].$lte}`;
    else if (name === "cenaSoSkidkoy")
      nameVariation = `${activeFilters[
        name
      ].$gte?.toLocaleString()} - ${activeFilters[
        name
      ].$lte?.toLocaleString()}`;
    else if (name === "skidka")
      nameVariation = activeFilters[name] === "true" ? "Со скидкой" : "";

    return nameVariation;
  }
};

const Filters = observer(
  /**
   *
   * @param clearFilter
   * @param setActiveFilters
   * @param activeFilters
   * @param catInProds
   * @param categForFilter
   * @param setCategForFilter
   * @param changeQueryURL
   * @param dirtyFilters
   * @param inSalePage
   * @param sale
   * @param search
   * @param changePage
   * @param setLastFilterPointChoose
   * @param harFilter
   * @param brandName
   * @param razdel
   * @param saleSlug
   * @param {string[]} orderUseFilter
   * @param {(string[])=>void} setOrderUseFilter
   * @return {JSX.Element}
   */
  ({
    clearFilter,
    setActiveFilters,
    activeFilters,
    catInProds,
    categForFilter,
    setCategForFilter,
    changeQueryURL,
    dirtyFilters,
    inSalePage,
    sale,
    search,
    changePage,
    setLastFilterPointChoose,
    harFilter,
    brandName,
    razdel,
    saleSlug,
    orderUseFilter,
    setOrderUseFilter,
    setParams,
  }) => {
    // const [volume, setVolume] = useState(0);
    // const [checked, setChecked] = useState(false);
    const [chooseFilterMobile, setChooseFilterMobile] = useState(null);
    const [chooseFilterTypeMobile, setChooseFilterTypeMobile] = useState(null);

    const isMobile = store.isMobile;

    const { categ } = store;

    const [priceSlider, setPriceSlider] = useState([
      activeFilters?.cenaSoSkidkoy?.$gte ?? clearFilter.minPrice,
      activeFilters?.cenaSoSkidkoy?.$lte ?? clearFilter.maxPrice,
    ]);
    const [priceInput, setPriceInput] = useState([
      activeFilters?.cenaSoSkidkoy?.$gte ?? clearFilter.minPrice,
      activeFilters?.cenaSoSkidkoy?.$lte ?? clearFilter.maxPrice,
    ]);
    const [yearSlider, setYearSlider] = useState([
      activeFilters.god?.$gte
        ? 2000 + +activeFilters?.god?.$gte
        : 2000 + clearFilter?.god?.minYear,
      activeFilters?.god?.$lte
        ? 2000 + +activeFilters?.god?.$lte
        : 2000 + clearFilter?.god?.maxYear,
    ]);

    useEffect(() => {
      setYearSlider([
        activeFilters.god?.$gte
          ? 2000 + +activeFilters?.god?.$gte
          : 2000 + clearFilter?.god?.minYear,
        activeFilters?.god?.$lte
          ? 2000 + +activeFilters?.god?.$lte
          : 2000 + clearFilter?.god?.maxYear,
      ]);
    }, [clearFilter?.god?.minYear, clearFilter?.god?.maxYear]);

    useEffect(() => {
      setPriceSlider([
        activeFilters?.cenaSoSkidkoy?.$gte ?? clearFilter.minPrice,
        activeFilters?.cenaSoSkidkoy?.$lte ?? clearFilter.maxPrice,
      ]);
    }, [clearFilter.minPrice, clearFilter.maxPrice]);

    const closeMobileFilter = () => {
      setChooseFilterMobile(null);
      setChooseFilterTypeMobile(null);
      document.querySelector("body").classList.remove("no-scroll");
      document.querySelector(".filter").classList.add("close");
    };

    const clearOrDitryFilter = (typeFilter, isSpec) =>
      activeFilters[typeFilter] ||
      (isSpec &&
        activeFilters.spec &&
        activeFilters.spec.some((variant) => variant.includes(typeFilter))) ||
      !dirtyFilters[typeFilter]
        ? clearFilter[typeFilter]
        : dirtyFilters[typeFilter];

    const getClearFilter = (typeFilter) => clearFilter[typeFilter];

    const getDirtyFilter = (typeFilter) => dirtyFilters[typeFilter];

    const pickFilterOld = (type, value, additValue) => {
      if (additValue) value = additValue + "-" + value;
      let isUpdated = false;
      if (activeFilters[type]) {
        if (!Array.isArray(activeFilters[type]))
          activeFilters[type] = [activeFilters[type]];

        const index = activeFilters[type].indexOf(value);
        if (index !== -1) {
          isUpdated = activeFilters[type].length > 1 || type !== "pol";

          if (activeFilters[type].length > 1) {
            activeFilters[type].splice(index, 1);
          } else if (type !== "pol") delete activeFilters[type];
          if (relatedFilter[type] && isUpdated) {
            relatedFilter[type].forEach((relType) => {
              if (activeFilters[relType]) {
                delete activeFilters[relType];
              }
            });
          }
        } else {
          activeFilters[type].push(value);
          isUpdated = true;
        }
      } else {
        activeFilters[type] = [value];
        orderUseFilter.push(type);
        isUpdated = true;
      }
      setLastFilterPointChoose(type);

      if (isUpdated)
        changeQueryURL({ pageChange: 1, activeFiltersChange: activeFilters });
    };

    const pickFilter = useCallback(
      (type, value, additValue) => {
        if (additValue) value = additValue + "-" + value;
        let isUpdated = false;

        setLastFilterPointChoose(type);

        setParams((params) => {
          const activeFilters = params.filter;

          if (activeFilters[type]) {
            if (!Array.isArray(activeFilters[type]))
              activeFilters[type] = [activeFilters[type]];

            const index = activeFilters[type].indexOf(value);
            if (index !== -1) {
              isUpdated = activeFilters[type].length > 1 || type !== "pol";

              if (activeFilters[type].length > 1) {
                activeFilters[type].splice(index, 1);
              } else if (type !== "pol") delete activeFilters[type];
              if (relatedFilter[type] && isUpdated) {
                relatedFilter[type].forEach((relType) => {
                  if (activeFilters[relType]) {
                    delete activeFilters[relType];
                  }
                });
              }
            } else {
              activeFilters[type].push(value);
              isUpdated = true;
            }
          } else {
            activeFilters[type] = [value];
            isUpdated = true;
          }

          return {
            ...params,
            filter: activeFilters,
            page: isUpdated ? 1 : params.page,
          };
        });
      },
      [setParams]
    );

    const openFilter = useCallback(
      (e, type, header) => {
        e.currentTarget.classList.toggle("open");
        setChooseFilterMobile(header);
        if (isMobile) {
          document.querySelector(".filter__body").classList.add("no-scroll");
          if (type === chooseFilterTypeMobile) setChooseFilterTypeMobile(null);
          else setChooseFilterTypeMobile(type);
        }
      },
      [isMobile, chooseFilterTypeMobile]
    );

    const pickRadioFilter = (type, value) => {
      if (type === "pol") {
        changeQueryURL({
          pageChange: 1,
          activeFiltersChange: {
            pol: value,
          },
        });
        return;
      }
      activeFilters[type] = value;
      changeQueryURL({ pageChange: 1, activeFiltersChange: activeFilters });
      // setActiveFilters({ ...activeFilters });
    };

    const checkInFilter = (type, value, additValue) => {
      if (additValue) value = additValue + "-" + value;
      // like ALL for pol
      if (type === "pol" && activeFilters[type] === undefined) return true;
      if (activeFilters[type] === undefined || activeFilters[type] === null)
        return false;

      return activeFilters[type].indexOf(value) !== -1;
    };

    const checkActiveFilterInType = (type, harName) => {
      if (harName) {
        return activeFilters[type]?.some((filter) => filter.includes(harName));
      }
      return !!activeFilters[type];
    };

    const categories = useMemo(() => {
      if (catInProds) {
        if (clearFilter.gruppaRazdelov)
          return categ.map((razdel, i) => {
            if (clearFilter.gruppaRazdelov.includes(razdel.name)) {
              const subChilds = razdel.childs[0].childs
                ? razdel.childs.map((subChild) => {
                    const childsHTML = [];
                    subChild.childs.forEach((child) => {
                      if (!clearFilter.kategoriya.includes(child.name)) return;
                      const active = categForFilter.kategoriya === child.name;
                      childsHTML.push(
                        <a
                          onClick={() => {
                            if (active) setCategForFilter({});
                            else setCategForFilter({ kategoriya: child.name });
                          }}
                          className={`filter__item-subdropdown-title ${
                            active ? " active pointer" : ""
                          }`}
                        >
                          {child.name}
                          {/* <span className="filter__item-num">10</span> */}
                        </a>
                      );
                    });
                    if (childsHTML.length)
                      return (
                        <div className="cat-item">
                          <div
                            className="filter__item-head filter__item-dropdown-head"
                            onClick={(sublink) => {
                              sublink.currentTarget.classList.toggle("open");
                            }}
                          >
                            <span className="filter__item-title">
                              {subChild.name}
                              {/* <span className="filter__item-num">10</span> */}
                            </span>
                            <i className="i-down" />
                          </div>
                          <div className="filter__item-subdropdown">
                            {childsHTML}
                          </div>
                        </div>
                      );
                  })
                : razdel.childs.map((subChild) => {
                    if (clearFilter.kategoriya.includes(subChild.name))
                      return (
                        <div>
                          <div className="filter__item-subdropdown-title">
                            <a
                              onClick={() => {
                                if (categForFilter.kategoriya === subChild.name)
                                  setCategForFilter({});
                                else
                                  setCategForFilter({
                                    ...categForFilter,
                                    kategoriya: subChild.name,
                                  });
                              }}
                              className="filter__item-title"
                            >
                              {subChild.name}
                              {/* <span className="filter__item-num">10</span> */}
                            </a>
                          </div>
                        </div>
                      );
                  });

              return (
                <div className="cat-item">
                  <div
                    className="filter__item-head"
                    onClick={(sublink) => {
                      sublink.currentTarget.classList.toggle("open");
                    }}
                  >
                    <span className="filter__item-title">
                      {razdel.name}
                      {/* <span className="filter__item-num">10</span> */}
                    </span>
                    <i className="i-down" />
                  </div>
                  <div className="filter__item-dropdown">{subChilds}</div>
                </div>
              );
            }
          });
      } else {
        const pathURL = document.location.pathname;

        return categ.map((razdel) => {
          if (razdel.childs) {
            let active = false;
            const subChilds =
              razdel.childs[0].childs !== undefined
                ? razdel.childs.map((subChild) => {
                    let activeChild = false;
                    const childsHTML = (
                      <div className="filter__item-subdropdown">
                        {subChild.childs !== undefined &&
                          subChild.childs.map((child) => {
                            if (child.justLink) return null;
                            const link = `/catalog/${razdel.slug}/${subChild.slug}/${child.slug}`;
                            if (link === pathURL) {
                              active = true;
                              activeChild = true;
                            }
                            return (
                              <NavLink
                                to={link}
                                className="filter__item-subdropdown-title"
                              >
                                {child.name}
                                {/* <span className="filter__item-num">10</span> */}
                              </NavLink>
                            );
                          })}
                      </div>
                    );
                    return (
                      <div className="cat-item">
                        <div
                          className={`filter__item-head filter__item-dropdown-head ${
                            activeChild ? "open" : ""
                          }`}
                          onClick={(sublink) => {
                            sublink.currentTarget.classList.toggle("open");
                          }}
                        >
                          <span className="filter__item-title">
                            {subChild.name}
                            {/* <span className="filter__item-num">10</span> */}
                          </span>
                          <i className="i-down" />
                        </div>
                        {childsHTML}
                      </div>
                    );
                  })
                : razdel.childs.map((subChild) => {
                    const link = `/catalog/${razdel.slug}/${subChild.slug}`;
                    if (link === pathURL) {
                      active = true;
                    }
                    return (
                      <div>
                        <div className="filter__item-subdropdown-title">
                          <NavLink
                            to={link}
                            className="filter__item-subdropdown-title"
                          >
                            {subChild.name}
                            {/* <span className="filter__item-num">10</span> */}
                          </NavLink>
                        </div>
                      </div>
                    );
                  });

            return (
              <div className="cat-item">
                <div
                  className={`filter__item-head ${active ? "open" : ""}`}
                  onClick={(sublink) => {
                    sublink.currentTarget.classList.toggle("open");
                  }}
                >
                  <span className="filter__item-title">
                    {razdel.name}
                    {/* <span className="filter__item-num">10</span> */}
                  </span>
                  <i className="i-down" />
                </div>
                <div className="filter__item-dropdown">{subChilds}</div>
              </div>
            );
          }
        });
      }
    }, [categ, catInProds, clearFilter, categForFilter]);

    const getNameActiveFilterNewByFilter =
      getNameActiveFilterNew(activeFilters);

    const harFilterHTML = harFilter?.map((harName) => {
      const clear = getClearFilter(harName);
      if (!clear?.length) return null;
      return (
        <FilterElement
          key={harName}
          type="spec"
          header={harName}
          isVisible={clearFilter?.pol?.length > 1}
          clearFilter={clear}
          dirtyFilter={getDirtyFilter(harName)}
          isActiveFilter={checkActiveFilterInType("spec", harName)}
          activeFilter={activeFilters?.spec}
          pickFilter={pickFilter}
          openFilter={openFilter}
          harName={harName}
        />
      );
    });

    const chooseTypeAvalible = clearFilter.maxAll !== undefined && (
      <div className="filter__item">
        <div
          className="filter__item-head open"
          onClick={(sublink) => {
            openFilter(sublink, "avalible", "Наличие");
          }}
        >
          <p className="filter__item-title">Наличие</p>
          <i className="i-down" />
        </div>

        <div className="filter__item-choice filter__item-gender">
          {clearFilter.maxAll > 0 && (
            <div className="filter__item-gender-var">
              <input
                type="radio"
                name="avalible-type"
                id="AllA"
                checked={activeFilters.avalible === undefined}
                onChange={() => pickRadioFilter("avalible", undefined)}
              />
              <label htmlFor="AllA">Все</label>
            </div>
          )}

          {clearFilter.maxWH > 0 && (
            <div className="filter__item-gender-var">
              <input
                type="radio"
                name="avalible-type"
                id="WH"
                checked={activeFilters.avalible === "WH"}
                onChange={() => pickRadioFilter("avalible", "WH")}
              />
              <label htmlFor="WH">Склад</label>
            </div>
          )}
          {clearFilter.maxSH > 0 && (
            <div className="filter__item-gender-var">
              <input
                type="radio"
                name="avalible-type"
                id="SH"
                checked={activeFilters.avalible === "SH"}
                onChange={() => pickRadioFilter("avalible", "SH")}
              />
              <label htmlFor="SH">Поставка</label>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div
        className={`col col-3 col-s-12 filter close ${
          sale && !saleSlug ? "mt50" : ""
        }`}
      >
        <div className="mobile-filters__top">
          {chooseFilterMobile !== null && (
            <i
              className="i-down mobile-filters__icon-back"
              onClick={() => {
                document
                  .querySelectorAll(".filter__item-head")
                  .forEach((el) => el.classList.remove("open"));
                setChooseFilterMobile(null);
                setChooseFilterTypeMobile(null);
                document
                  .querySelector(".filter__body")
                  .classList.remove("no-scroll");
              }}
            />
          )}

          <h2 className="mobile-filters__title">
            {chooseFilterMobile ?? "Фильтр"}
          </h2>
          <i
            className="i-close mobile-filters__icon-close"
            onClick={closeMobileFilter}
          />
        </div>
        <StickyBox
          offsetTop={isMobile ? 0 : 100}
          offsetBottom={isMobile ? 0 : 50}
          className="filter__body"
        >
          {!isMobile && (
            <div className="filter__item filter__item--cat">{categories}</div>
          )}
          {chooseTypeAvalible}

          <FilterElement
            type="pol"
            header="Коллекция"
            isVisible={clearFilter?.pol?.length > 1}
            clearFilter={getClearFilter("pol")}
            dirtyFilter={getDirtyFilter("pol")}
            isActiveFilter={checkActiveFilterInType("pol")}
            activeFilter={activeFilters?.pol}
            sort={POL_SORT}
            labels={POL_SORT_LOCALIZE}
            isDefaultOpen
            pickFilter={pickFilter}
            openFilter={openFilter}
            shouldSortByAvailable={false}
          />

          <FilterElement
            type="filterLabel"
            header="Строка для фильтра"
            isVisible={getClearFilter("filterLabel")?.length >= 1}
            clearFilter={getClearFilter("filterLabel")}
            dirtyFilter={getDirtyFilter("filterLabel")}
            isActiveFilter={checkActiveFilterInType("filterLabel")}
            activeFilter={activeFilters?.filterLabel}
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="tipProdukta"
            header="Тип продукта"
            isVisible={getClearFilter("tipProdukta")?.length >= 1}
            clearFilter={getClearFilter("tipProdukta")}
            dirtyFilter={getDirtyFilter("tipProdukta")}
            isActiveFilter={checkActiveFilterInType("tipProdukta")}
            activeFilter={activeFilters?.tipProdukta}
            withSearch
            isDefaultOpen
            isDesktopMaxSize
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="proizvoditelTehniki"
            header="Производитель техники"
            isVisible={getClearFilter("proizvoditelTehniki")?.length >= 1}
            clearFilter={getClearFilter("proizvoditelTehniki")}
            dirtyFilter={getDirtyFilter("proizvoditelTehniki")}
            isActiveFilter={checkActiveFilterInType("proizvoditelTehniki")}
            activeFilter={activeFilters?.proizvoditelTehniki}
            isDefaultOpen
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="_compatibilitiesFilter_year"
            header="Год выпуска техники"
            isVisible={
              (activeFilters.proizvoditelTehniki !== undefined ||
                (clearFilter.proizvoditelTehniki !== undefined &&
                  clearFilter.proizvoditelTehniki.length === 1)) &&
              (clearOrDitryFilter("_compatibilitiesFilter_year")?.length > 1 ||
                checkActiveFilterInType("_compatibilitiesFilter_year"))
            }
            clearFilter={getClearFilter("_compatibilitiesFilter_year")}
            dirtyFilter={getDirtyFilter("_compatibilitiesFilter_year")}
            isActiveFilter={checkActiveFilterInType(
              "_compatibilitiesFilter_year"
            )}
            activeFilter={activeFilters?._compatibilitiesFilter_year}
            isDefaultOpen
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="_compatibilitiesFilter_sharedModel"
            header="Серия техники"
            isVisible={
              (activeFilters.proizvoditelTehniki !== undefined ||
                (clearFilter.proizvoditelTehniki !== undefined &&
                  clearFilter.proizvoditelTehniki.length === 1)) &&
              (activeFilters["_compatibilitiesFilter_year"] !== undefined ||
                (clearFilter["_compatibilitiesFilter_year"] !== undefined &&
                  clearFilter["_compatibilitiesFilter_year"].length === 1)) &&
              getClearFilter("_compatibilitiesFilter_sharedModel")?.length > 1
            }
            clearFilter={getClearFilter("_compatibilitiesFilter_sharedModel")}
            dirtyFilter={getDirtyFilter("_compatibilitiesFilter_sharedModel")}
            isActiveFilter={checkActiveFilterInType(
              "_compatibilitiesFilter_sharedModel"
            )}
            activeFilter={activeFilters?._compatibilitiesFilter_sharedModel}
            isDefaultOpen
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="_compatibilitiesFilter_model"
            header="Модель техники"
            isVisible={
              (activeFilters.proizvoditelTehniki !== undefined ||
                (clearFilter.proizvoditelTehniki !== undefined &&
                  clearFilter.proizvoditelTehniki.length === 1)) &&
              (activeFilters["_compatibilitiesFilter_year"] !== undefined ||
                (clearFilter["_compatibilitiesFilter_year"] !== undefined &&
                  clearFilter["_compatibilitiesFilter_year"].length === 1)) &&
              (activeFilters["_compatibilitiesFilter_sharedModel"] !==
                undefined ||
                (clearFilter["_compatibilitiesFilter_sharedModel"] !==
                  undefined &&
                  clearFilter["_compatibilitiesFilter_sharedModel"].length ===
                    1)) &&
              getClearFilter("_compatibilitiesFilter_model")?.length > 1
            }
            clearFilter={getClearFilter("_compatibilitiesFilter_model")}
            dirtyFilter={getDirtyFilter("_compatibilitiesFilter_model")}
            isActiveFilter={checkActiveFilterInType(
              "_compatibilitiesFilter_model"
            )}
            activeFilter={activeFilters?._compatibilitiesFilter_model}
            isDefaultOpen
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="brend"
            header="Бренды"
            isVisible={
              clearFilter.brend !== undefined && clearFilter.brend.length > 1
            }
            clearFilter={getClearFilter("brend")}
            dirtyFilter={getDirtyFilter("brend")}
            isActiveFilter={checkActiveFilterInType("brend")}
            activeFilter={activeFilters?.brend}
            isDefaultOpen
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="model"
            header="Модель"
            isVisible={getClearFilter("model")?.length > 1}
            clearFilter={getClearFilter("model")}
            dirtyFilter={getDirtyFilter("model")}
            isActiveFilter={checkActiveFilterInType("model")}
            activeFilter={activeFilters?.model}
            // isDefaultOpen
            withSearch
            pickFilter={pickFilter}
            openFilter={openFilter}
          />

          <FilterElement
            type="perechenRazmerov"
            header="Размеры"
            isVisible={
              clearFilter.perechenRazmerov !== undefined &&
              Object.keys(clearFilter.perechenRazmerov).length > 0
            }
            clearFilter={getClearFilter("perechenRazmerov")}
            dirtyFilter={getDirtyFilter("perechenRazmerov")}
            isActiveFilter={checkActiveFilterInType("perechenRazmerov")}
            activeFilter={activeFilters?.perechenRazmerov}
            pickFilter={pickFilter}
            openFilter={openFilter}
            addition={clearFilter.haveWithoutSize}
          />

          {harFilterHTML}
          {!inSalePage &&
            !sale &&
            clearOrDitryFilter("skidka") !== undefined &&
            clearOrDitryFilter("skidka").length > 1 && (
              <div
                className={`filter__item filter__item_m-line ${
                  checkActiveFilterInType("skidka") ? "active" : ""
                }`}
              >
                <div className="filter__item-head">
                  <label htmlFor="switch" className="filter__item-title">
                    Только со скидкой
                  </label>
                  <label className="switch">
                    <input
                      id="switch"
                      type="checkbox"
                      checked={activeFilters.skidka}
                      onChange={() => {
                        if (activeFilters.skidka) {
                          delete activeFilters.skidka;
                        } else {
                          activeFilters.skidka = true;
                        }
                        changeQueryURL({ activeFiltersChange: activeFilters });
                        // setActiveFilters({ ...activeFilters });
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            )}
          {getDirtyFilter("god") !== undefined && (
            <FilterElementRange
              type="god"
              title="Год коллекции"
              isInFilter={checkActiveFilterInType("god")}
              openFilter={openFilter}
              getNameActiveFilter={getNameActiveFilter}
              activeFilters={activeFilters}
              initialValue={[
                getDirtyFilter("god").minYear + 2000,
                getDirtyFilter("god").maxYear + 2000,
              ]}
              minValue={
                getDirtyFilter("god").minYear
                  ? getDirtyFilter("god").minYear + 2000
                  : undefined
              }
              maxValue={
                getDirtyFilter("god").maxYear
                  ? getDirtyFilter("god").maxYear + 2000
                  : undefined
              }
              onAfterChange={(e) => {
                activeFilters.god = {
                  $gte: e[0] - 2000,
                  $lte: e[1] - 2000,
                };
                if (
                  e[0] - 2000 === getDirtyFilter("god").minYear &&
                  +e[1] - 2000 === getDirtyFilter("god").maxYear
                )
                  delete activeFilters.god;

                setLastFilterPointChoose("god");
                changeQueryURL({
                  activeFiltersChange: { ...activeFilters },
                });
              }}
              changeQueryURL={changeQueryURL}
            />
          )}
          {getDirtyFilter("cenaSoSkidkoy") !== undefined && (
            <FilterElementRange
              type="cenaSoSkidkoy"
              title="Цена, ₽"
              isInFilter={checkActiveFilterInType("cenaSoSkidkoy")}
              openFilter={openFilter}
              getNameActiveFilter={getNameActiveFilter}
              activeFilters={activeFilters}
              initialValue={[
                getDirtyFilter("cenaSoSkidkoy").minPrice,
                getDirtyFilter("cenaSoSkidkoy").maxPrice,
              ]}
              minValue={getDirtyFilter("cenaSoSkidkoy").minPrice}
              maxValue={getDirtyFilter("cenaSoSkidkoy").maxPrice}
              onAfterChange={(e) => {
                const priceFilter = {};
                if (+e[0]) {
                  priceFilter.$gte = +e[0];
                }
                if (+e[1]) {
                  priceFilter.$lte = +e[1];
                }
                activeFilters.cenaSoSkidkoy = priceFilter;
                if (
                  +e[0] === getDirtyFilter("cenaSoSkidkoy").minPrice &&
                  +e[1] === getDirtyFilter("cenaSoSkidkoy").maxPrice
                )
                  delete activeFilters.cenaSoSkidkoy;

                setLastFilterPointChoose("cenaSoSkidkoy");

                changeQueryURL({
                  activeFiltersChange: { ...activeFilters },
                });
              }}
              changeQueryURL={changeQueryURL}
            />
          )}
          <button
            className="filter__button"
            onClick={() =>
              changeQueryURL({
                activeFiltersChange: {
                  pol: activeFilters.pol,
                },
              })
            }
          >
            Сбросить фильтры
          </button>
        </StickyBox>
        <div className="filter__buttons">
          {isMobile && (
            <button
              className={`btn btn_secondary btn_full mr10 ${
                (chooseFilterTypeMobile &&
                  !activeFilters[chooseFilterTypeMobile]) ||
                chooseFilterTypeMobile === "pol"
                  ? "deactive"
                  : ""
              }`}
              onClick={() => {
                if (chooseFilterTypeMobile) {
                  if (activeFilters[chooseFilterTypeMobile]) {
                    if (chooseFilterTypeMobile === "spec") {
                      const newSpec = [];
                      activeFilters[chooseFilterTypeMobile].forEach((spec) => {
                        if (!spec.includes(chooseFilterMobile)) {
                          newSpec.push(spec);
                        }
                      });
                      if (newSpec.length)
                        activeFilters[chooseFilterTypeMobile] = newSpec;
                      else delete activeFilters[chooseFilterTypeMobile];
                    } else {
                      delete activeFilters[chooseFilterTypeMobile];
                    }
                    changeQueryURL({
                      activeFiltersChange: activeFilters,
                    });
                  }
                } else {
                  setPriceInput([clearFilter.minPrice, clearFilter.maxPrice]);
                  changeQueryURL({
                    activeFiltersChange: {
                      pol: activeFilters.pol,
                    },
                  });
                }
              }}
            >
              {chooseFilterTypeMobile
                ? activeFilters[chooseFilterTypeMobile]
                  ? `Сбросить${
                      Array.isArray(activeFilters[chooseFilterTypeMobile])
                        ? ` (${activeFilters[chooseFilterTypeMobile].length})`
                        : ""
                    }`
                  : "Сбросить"
                : "Сбросить всё"}
            </button>
          )}
          <button
            className="btn btn_primary btn_full"
            onClick={() => {
              if (chooseFilterTypeMobile) {
                document
                  .querySelector(".filter__item > .filter__item-head.open")
                  .classList.remove("open");
                setChooseFilterTypeMobile(null);
                setChooseFilterMobile(null);
              } else {
                closeMobileFilter();
              }
            }}
          >
            {chooseFilterTypeMobile ? "Готово" : "Применить"}
          </button>
        </div>
      </div>
    );
  }
);

export default Filters;
