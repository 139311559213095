import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";

const { createSliderWithTooltip } = Slider;
const RangeTooltip = createSliderWithTooltip(Slider.Range);

/**
 *
 * @param {object} props
 * @param {string} props.type - Тип фильтра
 * @param {string} props.title - Заголовок фильтра
 * @param {object} props.activeFilters - Активные фильтры
 * @param {function} props.onAfterChange - Функция вызываемая после изменения
 * @param {function} props.changeQueryURL - Функция для изменения URL запроса
 * @param {[number, number]} props.initialValue - Начальное значение слайдера
 * @param {number} props.minValue - Минимальное значение слайдера
 * @param {number} props.maxValue - Максимальное значение слайдера
 * @param {boolean} props.isInFilter - Является ли фильтр выбранным
 * @param {function} props.openFilter - Функция для открытия фильтра
 * @param {function} props.getNameActiveFilter - Функция для получения активного значения фильтра
 * @returns {React.ReactElement}
 */
export const FilterElementRange = ({
  type,
  title,
  activeFilters,
  onAfterChange,
  changeQueryURL,
  initialValue,
  minValue,
  maxValue,
  isInFilter,
  openFilter,
  getNameActiveFilter,
}) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const isPrice = useMemo(() => type === "cenaSoSkidkoy", [type]);

  const processValue = useCallback(
    (value) => {
      if (value === undefined) return;
      if (type === "god") return +value + 2000;
      return +value;
    },
    [type]
  );

  useEffect(() => {
    const value = Math.max(
      processValue(activeFilters?.[type]?.$gte) ?? minValue,
      minValue
    );

    setInputValue((p) => (p[0] !== value ? [value, p[1]] : p));
  }, [activeFilters?.[type]?.$gte, minValue]);

  useEffect(() => {
    const value = Math.min(
      processValue(activeFilters?.[type]?.$lte) ?? maxValue,
      maxValue
    );

    setInputValue((p) => (p[1] !== value ? [p[0], value] : p));
  }, [activeFilters?.[type]?.$lte, maxValue]);

  const inputs = useMemo(() => {
    if (!isPrice) return null;
    return (
      <div className="filter__item-price-count">
        <input
          type="text"
          value={
            inputValue?.[0] === minValue && !activeFilters?.[type]?.$gte
              ? ""
              : inputValue?.[0]
          }
          placeholder={minValue}
          onChange={(e) =>
            setInputValue((p) => [processValue(e.target.value), p[1]])
          }
          onBlur={(e) => {
            let value = +e.target.value;
            if (isNaN(value)) return;
            value = processValue(value);
            if (value > minValue && value < maxValue) {
              if (!activeFilters?.[type])
                activeFilters[type] = {
                  $gte: value,
                };
              else activeFilters[type].$gte = value;

              changeQueryURL({
                activeFiltersChange: activeFilters,
              });
            }
            setInputValue((p) => [minValue, p[1]]);
          }}
        />
        <span className="filter__item-price-dash"></span>
        <input
          type="text"
          value={
            inputValue?.[1] === maxValue && !activeFilters?.[type]?.$lte
              ? ""
              : inputValue?.[1]
          }
          placeholder={maxValue}
          onChange={(e) =>
            setInputValue((p) => [p[0], processValue(e.target.value)])
          }
          onBlur={(e) => {
            let value = +e.target.value;
            if (isNaN(value)) return;
            value = processValue(value);
            if (value > minValue && value < maxValue) {
              if (!activeFilters?.[type])
                activeFilters[type] = {
                  $lte: e.target.value,
                };
              else activeFilters[type].$lte = value;

              changeQueryURL({
                activeFiltersChange: activeFilters,
              });
            }
            setInputValue((p) => [p[0], maxValue]);
          }}
        />
      </div>
    );
  }, [
    isPrice,
    minValue,
    maxValue,
    inputValue?.[0],
    inputValue?.[1],
    setInputValue,
    activeFilters?.[type]?.$lte,
    activeFilters?.[type]?.$gte,
    changeQueryURL,
  ]);

  const additionalClass = useMemo(() => {
    return isPrice ? "filter__item-price" : "filter__item-year";
  }, [isPrice]);

  const RangeComponent = useMemo(() => {
    if (isPrice) return Range;
    return RangeTooltip;
  }, [isPrice]);

  const isDisabled = useMemo(() => {
    return !minValue && !maxValue;
  }, [minValue, maxValue]);

  return (
    <div
      className={`filter__item ${isPrice ? additionalClass : ""} ${
        isInFilter ? "active" : ""
      }`}
      style={isDisabled ? { pointerEvents: "none", opacity: 0.5 } : {}}
    >
      <div
        className="filter__item-head"
        onClick={(sublink) => {
          openFilter(sublink, type, title);
        }}
      >
        <p className="filter__item-title">{title}</p>
        <div className="filter__item-right">
          <p>{getNameActiveFilter(activeFilters, type)}</p>
          <i className="i-down" />
        </div>
      </div>
      <div className={`filter__item-choice ${additionalClass}`}>
        {inputs}
        <div className="filter__item-slider">
          {React.createElement(RangeComponent, {
            min: minValue,
            max: maxValue,
            value: inputValue,
            onChange: (e) => {
              setInputValue([e[0], e[1]]);
            },
            onAfterChange: onAfterChange,
            tipProps: {
              placement: "bottom",
              visible: !isPrice,
            },
          })}
        </div>
      </div>
    </div>
  );
};
