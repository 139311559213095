import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  memo,
} from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import store from "../../MobX";
import { FilterElementSize } from "./FilterElementByType/FilterElementSize";

const simpleBarMaxHeightMobile = "calc(100% - 60px)";

const FilterElementHeader = ({
  isActiveFilter,
  isMobile,
  isDefaultOpen,
  openFilter,
  type,
  header,
  // getNameActiveFilter,
  nameActiveFilter,
  children,
  isOpacity,
  isVisible,
}) => {
  const onOpenFilter = useCallback(
    (sublink) => {
      openFilter(sublink, type, header);
    },
    [openFilter, type, header]
  );

  return (
    <div
      className={`filter__item ${isActiveFilter ? "active" : ""} ${
        isVisible ? "" : "disabled"
      }`}
      style={{
        opacity: isOpacity ? 0.5 : 1,
      }}
    >
      <div
        className={`filter__item-head ${
          isMobile ? "" : isDefaultOpen ? "open" : ""
        }`}
        onClick={onOpenFilter}
      >
        <p className="filter__item-title">{header}</p>
        <div className="filter__item-right">
          <p>{nameActiveFilter?.join?.(", ")}</p>
          <i className="i-down" />
        </div>
      </div>
      {children}
    </div>
  );
};

const polToLocalize = {
  MAN: "Мужское",
  WOMAN: "Женское",
  JUNIOR: "Детское",
  "": "Унисекс",
  undefined: "Все",
};

const FilterElement = memo(
  /**
   *
   * @param {object} props
   * @param {string} props.type
   * @param {string} props.header
   * @param {Record<string, string>} props.labels
   * @param {string[]} props.sort
   * @param {boolean} props.isVisible
   * @param {boolean} [props.withSearch]
   * @param {boolean} [props.isDefaultOpen]
   * @param {boolean} [props.isActiveFilter]
   * @param {boolean} [props.isDesktopMaxSize]
   * @param {object} props.clearFilter
   * @param {object} props.dirtyFilter
   * @param {object} props.activeFilter
   * @param {string} [props.harName]
   * @param {(type:string, value:any, subkey?:string)=>void} props.pickFilter
   * @param {(type:string, value:any, subkey?:string)=>boolean} props.checkInFilter
   * @param {(event:any, type: string, header:string)=>void} props.openFilter
   * @param {(type:string, specName?:string)=>string[]} props.getNameActiveFilter
   * @param {any} [props.addition]
   * @param {boolean} [props.shouldSortByAvailable=true]
   */
  (props) => {
    const {
      type,
      header,
      labels = {},
      sort,
      isVisible,
      withSearch,
      isDefaultOpen,
      isActiveFilter,
      isDesktopMaxSize,
      clearFilter,
      dirtyFilter,
      activeFilter,
      harName,
      pickFilter,
      openFilter,
      addition,
      shouldSortByAvailable = true,
    } = props;

    const [search, setSearch] = useState("");
    const scrollRef = useRef(null);

    useEffect(() => {
      if (scrollRef.current) scrollRef.current.scrollY(0);
    }, [dirtyFilter]);

    const isMobile = store.isMobile;

    const maxHeightStyle = useMemo(() => {
      const maxHeight = isMobile
        ? simpleBarMaxHeightMobile
        : isDesktopMaxSize
        ? 370
        : 170;

      return {
        maxHeight,
      };
    }, [isMobile, isDesktopMaxSize]);

    const sortedFilter = useMemo(() => {
      if (!clearFilter?.length) return [];
      return clearFilter?.sort((a, b) => {
        if (shouldSortByAvailable && dirtyFilter?.length) {
          if (dirtyFilter.includes(a) && !dirtyFilter.includes(b)) return -1;
          if (dirtyFilter.includes(b) && !dirtyFilter.includes(a)) return 1;
        }

        if (sort?.length) return sort.indexOf(a) - sort.indexOf(b);

        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    }, [dirtyFilter, clearFilter, sort, shouldSortByAvailable]);

    const checkInFilter = useCallback(
      (value, additValue) => {
        if (additValue) value = additValue + "-" + value;
        // like ALL for pol
        if (type === "pol" && activeFilter === undefined) return true;
        if (activeFilter === undefined || activeFilter === null) return false;

        return activeFilter?.indexOf(value) !== -1;
      },
      [activeFilter, type]
    );

    const nameActiveFilter = useMemo(() => {
      if (type === "pol" && activeFilter)
        return polToLocalize[activeFilter?.[type]];
      if (!activeFilter) return [""];
      else if (Array.isArray(activeFilter)) {
        return activeFilter.map((elem, i) => {
          let nameVariation = elem;

          if (type === "perechenRazmerov")
            nameVariation = nameVariation.split("-")[1];
          else if (type === "spec") {
            let specFilterSplit = (nameVariation = nameVariation.split("-"));
            if (specFilterSplit[0] === harName) {
              nameVariation = specFilterSplit[1];
            } else nameVariation = null;
          }

          return nameVariation;
        });
      } else {
        let nameVariation = activeFilter;

        if (type === "god")
          nameVariation = `20${activeFilter.$gte} - 20${activeFilter.$lte}`;
        else if (type === "cenaSoSkidkoy")
          nameVariation = `${activeFilter.$gte?.toLocaleString()} - ${activeFilter.$lte?.toLocaleString()}`;
        else if (type === "skidka")
          nameVariation = activeFilter === "true" ? "Со скидкой" : "";

        return nameVariation;
      }
    }, [activeFilter, type, harName]);

    if (!isVisible) return null;

    if (type === "perechenRazmerov") {
      return (
        <FilterElementHeader
          isVisible={isVisible}
          key={type}
          openFilter={openFilter}
          header={header}
          nameActiveFilter={nameActiveFilter}
          type={type}
          isActiveFilter={isActiveFilter}
          isMobile={isMobile}
          isDefaultOpen={isDefaultOpen}
        >
          <FilterElementSize
            sizes={dirtyFilter}
            availableSizes={dirtyFilter}
            pickFilter={pickFilter}
            checkInFilter={checkInFilter}
            haveWithoutSize={addition}
          />
        </FilterElementHeader>
      );
    }

    // if (!dirtyFilter?.length) return null;

    const elementHTML = sortedFilter?.map((el) => {
      if (!el || el === "required") return null;

      const inFilter = checkInFilter(el, harName);

      let searchValid = !search.length;

      if (search.length) {
        const searchLowerCase = search.toLowerCase();
        searchValid = el?.toLowerCase().includes(searchLowerCase);
      }

      const isAvailable = searchValid && dirtyFilter?.includes(el);

      return searchValid ? (
        <div
          key={el}
          className={`filter__item-brand-var ${isAvailable ? "" : "disabled"}`}
          onClick={() => pickFilter(type, el, harName)}
        >
          <input type="checkbox" checked={inFilter} readOnly />
          <label>{labels[el] || el}</label>
        </div>
      ) : null;
    });

    const isEmpty = !dirtyFilter?.length;

    return (
      <FilterElementHeader
        isVisible={isVisible}
        key={type}
        openFilter={openFilter}
        header={header}
        nameActiveFilter={nameActiveFilter}
        type={type}
        isActiveFilter={isActiveFilter}
        isMobile={isMobile}
        isDefaultOpen={isDefaultOpen}
        isOpacity={isEmpty}
      >
        <div className="filter__item-choice filter__item-brand no-scroll">
          {withSearch ? (
            <div>
              <input
                type="text"
                placeholder="Поиск..."
                className="filter__item-brand-search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          ) : null}
          <SimpleBar style={maxHeightStyle} autoHide={false} ref={scrollRef}>
            {elementHTML}
          </SimpleBar>
        </div>
      </FilterElementHeader>
    );
  }
);

export default FilterElement;
