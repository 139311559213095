import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import noImage from "../../img/noImage.png";
import { FILES_URL, sortSizes } from "../../jsCommon/constants";
import store from "../../MobX";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import localStorage from "mobx-localstorage";
import { GIFT_DATA_PRODS } from "../CartCard/giftPageData";
import { DISCOUNTED_KIT_PIN } from "../../jsCommon/common";

const ProdCard = ({
  prod,
  clickHand,
  inSalePage,
  inDiscountedKitPage,
  avalibleFilter,
  inNewTab,
  upSale,
  crossSale,
}) => {
  const [img, setImg] = useState(
    prod.izobrazheniya && prod.izobrazheniya.length
      ? FILES_URL + prod.izobrazheniya[0].formats.medium.url
      : prod.vneshnieIzobrazheniya
      ? prod.vneshnieIzobrazheniya.split(";")[0]
      : null
  );

  useEffect(() => {
    if (prod.imya === "Подарочная страница") {
      setImg(
        FILES_URL + GIFT_DATA_PRODS["0"].izobrazheniya[0].formats.medium.url
      );
    } else
      setImg(
        prod.izobrazheniya && prod.izobrazheniya.length
          ? FILES_URL + prod.izobrazheniya[0].formats.medium.url
          : prod.vneshnieIzobrazheniya
          ? prod.vneshnieIzobrazheniya.split(";")[0]
          : noImage
      );
  }, [prod]);

  const filterSizeToViewInCard = (size) => {
    let isAvailable =
      (!inSalePage || size.pricesaleretail === newPrice) &&
      (!inDiscountedKitPage || size.isDiscountedKit);

    // if (avalibleFilter) {
    //   if (avalibleFilter === "WH") {
    //     isAvailable = isAvailable && size.realwarehouseavailable > 0;
    //   } else if (avalibleFilter === "SH") {
    //     isAvailable = isAvailable && size.virtualwarehouseavailable > 0;
    //   }
    // }

    return isAvailable;
  };

  const haventSale = prod.cena === prod.cenaSoSkidkoy,
    sale = haventSale
      ? null
      : `${Math.round(100 - (prod.cenaSoSkidkoy / prod.cena) * 100)}`,
    news = prod.novyy ? true : false,
    price = haventSale ? prod.cena : null,
    oldPrice = haventSale ? null : prod.cena,
    newPrice = haventSale ? null : prod.cenaSoSkidkoy,
    name = prod.imya,
    size =
      prod.razmery[0] !== undefined &&
      prod.razmery[0].filtersize !== "Без размера"
        ? prod.razmery
            .filter((s) => filterSizeToViewInCard(s))
            .map((e) => e.filtersize)
            .sort()
            .sort((a, b) => {
              return sortSizes.indexOf(a) - sortSizes.indexOf(b);
            })
            .join(", ")
        : null,
    isGiftPage = name === "Подарочная страница";

  let link = `/product/${prod.slug}`;

  if (inSalePage) {
    link += "?sale";
  } else if (inDiscountedKitPage) {
    link += "?discounted-kit";
  } else if (sale && prod.razmery.length && prod.razmery.length > 1) {
    prod.razmery.forEach((razmer) => {
      if (newPrice === razmer.pricesaleretail) {
        link = `/product/${prod.slug}?${razmer.filtersize}`;
      }
    });
  }

  const settingsNavLink = {};
  if (inNewTab) settingsNavLink.target = "_blank";

  const haveStock = prod.razmery.some(
    (size) => size.realwarehouseavailable || size.virtualwarehouseavailable
  );

  const discountedKitPin =
    prod.isDiscountedKit && store.getPinText(DISCOUNTED_KIT_PIN);

  return (
    <NavLink
      to={link}
      className="prod-card"
      key={prod._id}
      {...settingsNavLink}
      onClick={(e) => {
        e.stopPropagation();
        if (upSale) {
          const slugs = localStorage.get("u-slugs");

          if (!slugs) {
            localStorage.set("u-slugs", [prod.slug]);
          } else if (!slugs.includes(prod.slug)) {
            slugs.push(prod.slug);
            localStorage.set("u-slugs", slugs);
          }
        }
        if (crossSale) {
          const slugs = localStorage.get("c-slugs");

          if (!slugs) {
            localStorage.set("c-slugs", [prod.slug]);
          } else if (!slugs.includes(prod.slug)) {
            slugs.push(prod.slug);
            localStorage.set("c-slugs", slugs);
          }
        }
        // e.preventDefault()
        // if (!e.ctrlKey && !e.metaKey && !inNewTab) {
        //   store.activeProd = prod;
        //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
        // }
        if (clickHand) {
          clickHand();
        }
      }}
    >
      <div className="prod-card__img">
        <div className="prod-card__img-wrp">
          <LazyLoadImage
            src={img}
            effect="blur"
            onError={async () => setImg(noImage)}
            alt={`${name}${
              prod.cvet !== undefined && prod.cvet !== null && prod.cvet !== ""
                ? `, цвет: ${prod.cvet}`
                : ""
            }`}
            title={name}
          />

          {/* <img
          src={
            img
              ? FILES_URL + img.formats.medium.url
              : alternativImg
              ? alternativImg[0]
              : chelik
          }
          className="prod-card__img"
        /> */}
          {size !== undefined && size !== null && (
            <div className="prod-card__size p sm">{size}</div>
          )}
          <div className="prod-card__bages">
            {sale ? <div className="prod-card__sale">{sale}%</div> : null}
            {news ? <div className={`prod-card__news`}>новинка</div> : null}
            {prod.predzakaz && !haveStock ? (
              <div className={`prod-card__bage pin`}>предзаказ</div>
            ) : null}
            {discountedKitPin ? (
              <div className={`prod-card__bage pin`}>{discountedKitPin}</div>
            ) : null}
          </div>
        </div>
      </div>
      {oldPrice ? (
        <div className="prod-card__comparison">
          <div className="prod-card__comparison-old">
            {oldPrice?.toLocaleString()} ₽
          </div>
          <div className="prod-card__comparison-new">
            {newPrice?.toLocaleString()} ₽
          </div>
        </div>
      ) : (
        <div className="prod-card__price">
          {isGiftPage ? "" : price?.toLocaleString()} {isGiftPage ? "\n" : "₽"}
        </div>
      )}
      <p className="prod-card__name">{name}</p>
    </NavLink>
  );
};

export default ProdCard;
