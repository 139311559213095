import React, { useEffect, useState, useMemo, useCallback } from "react";
import { FILES_URL, sortSizes } from "../../jsCommon/constants";
import Filters from "../../components/Filters/Filters";
import ProdCard from "../../components/ProdCard/ProdCard";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import Drop from "../../components/Drop/Drop";
import api from "../../jsCommon/api";
import store from "../../MobX";
import { observer } from "mobx-react-lite";
import Loadable from "react-loadable";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import queryString from "query-string";
import Slider from "react-slick";
import {
  decodeString,
  parseEditorJsToDesc,
  processEditorJsHTML,
  sendNotFoundPath,
} from "../../funcs";
import Helmet from "react-helmet";
import ProdList from "../../components/ProdList/ProdList";
import { useCatalog, useCatalogDescription } from "./lib/useCatalog";
import {
  useBrandQueries,
  useCatalogQueries,
  useCategoryDescriptionQueries,
  useLandingQueries,
  useSaleQueries,
  useSalesQueries,
} from "./lib/useCatalogQueries";
import useUrlParamsWithQueryParams from "./lib/useUrlParamsWithQueryParams";
import useActiveFilters from "./lib/useActiveFilters";
import useBreadcrumbs from "./lib/useBreadcrumbs";
import { LoadingOverlay } from "src/components/LoadingOverlay/LoadingOverlay";
import { EmptyCatalog } from "./EmptyCatalog";

const SaleCard = ({ sale }) => {
  const { imya, izobrazhenie, kratkoeOpisanie, slug } = sale;
  let { link } = sale;

  if (link && link.includes("https://motorfirst.ru/"))
    link = link.replace("https://motorfirst.ru/", "/");

  const url = link ? link : `/sale/${slug}`;

  return (
    <NavLink to={url} className="sale-card-link">
      <div className="sale-card">
        <img
          className="sale-card__img"
          src={FILES_URL + izobrazhenie?.formats?.medium?.url}
        />
        <h3 className="sale-card__title">{imya}</h3>
        <p className="sale-card__descr">{kratkoeOpisanie}</p>
      </div>
    </NavLink>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const settings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Catalog = observer(
  ({
    routeProps,
    catalogFetch,
    search,
    setNoScroll,
    razdel,
    subrazvel,
    point,
    sale,
    saleSlug,
    dontCategInTop,
    generateSlug,
    brandName,
    personalUID,
    isDiscountedKit,
  }) => {
    const options = [
      { name: "Выбор Motorfirst" },
      { name: "По возрастанию" },
      { name: "По убыванию" },
      // { name: "Новинки" },
      // { name: "Старое" },
    ];

    const history = useHistory();
    const location = useLocation();

    const [categoriesForFilter, setCategoriesForFilter] = useState({});
    const [activeSort, setActiveSort] = useState(null);
    const [typePageChange, setTypePageChange] = useState("pagin");

    const [lastChoosenFilterKey, setLastChoosenFilterKey] = useState(null);

    const [orderUseFilter, setOrderUseFilter] = useState([]);

    const {
      params,
      setParams,
      setFilter: setActiveFilters,
      setPage,
      updateFilter,
      setSearch,
    } = useUrlParamsWithQueryParams();

    const activeFilters = useMemo(() => {
      return params.filter;
    }, [params.filter]);

    const { page, search: searchString } = params;

    const {
      brandQuery: { data: brand },
    } = useBrandQueries(brandName);

    const descriptionPicture = useMemo(() => {
      if (brand?.logotip?.formats?.medium?.url) {
        return FILES_URL + brand.logotip.formats.medium.url;
      }
      return null;
    }, [brand]);

    const {
      salesQuery: { data: sales },
    } = useSalesQueries(sale);

    const {
      saleQuery: { data: saleData },
    } = useSaleQueries(saleSlug);

    const {
      landingQuery: { data: landing },
    } = useLandingQueries(generateSlug);

    const { catalogIsLoad, slugToSEO } = store;

    const { productsQuery } = useCatalogQueries(
      {
        params,
        point,
        subrazvel,
        razdel,
        sale,
        saleSlug,
        landingSlug: generateSlug,
        brandName,
        personalUID,
        activeSort,
        categoriesForFilter,
        isCategoryListRequest: catalogFetch,
        searchString,
        typePageChange,
        lastChoosenFilterKey,
      },
      updateFilter,
      setPage
    );

    const {
      data: {
        products = [],
        filter: dirtyFilters = {},
        clearFilter = {},
        propertyFilter,
        requiredFilterToSet,
      } = {},
      isFetching,
    } = productsQuery;

    useEffect(() => {
      if (!requiredFilterToSet) return;
      const updateFilters = {};
      Object.keys(requiredFilterToSet || {}).forEach((key) => {
        if (!activeFilters[key]) {
          updateFilters[key] = requiredFilterToSet[key];
        }
      });

      if (Object.keys(updateFilters).length) updateFilter(updateFilters);
    }, [requiredFilterToSet]);

    const { header, categoriesOnTop, landingOnTop } = useCatalog({
      point,
      subrazvel,
      razdel,
      sale,
      personalUID,
      searchString,
      count: dirtyFilters?.count,
      saleData,
      landing,
      brand,
    });

    const {
      categoryDescriptionQuery: { data: categoryDescriptionData },
    } = useCategoryDescriptionQueries({
      header,
      generateSlug,
      brandName,
    });

    const description = useCatalogDescription({
      landing,
      brand,
      categoryDescriptionData,
      point,
      subrazvel,
      razdel,
    });

    const isMobile = store.isMobile;

    const changePage = (page, type) => {
      setTypePageChange(type);
      setPage(page);
      if (type === "pagin")
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };

    /**
     * Updates the query URL based on the provided parameters.
     *
     * @param {Object} options - The options object.
     * @param {number} [options.pageChange] - The new page value.
     * @param {Object} options.activeFiltersChange - The new active filters object.
     * @param {string} options.searchStringChange - The new search string.
     */
    const changeQueryURL = useCallback(
      ({ pageChange, activeFiltersChange, searchStringChange }) => {
        const filter = activeFiltersChange ?? activeFilters;

        const query = {
          filter,
          page: pageChange ?? page,
        };

        if (searchString || searchStringChange) {
          query.search = searchStringChange ?? searchString;
        }

        setParams(query);
      },
      [activeFilters, setParams]
    );

    const checkManySearchPath = (queryPath) => {
      if (queryPath?.split("?").length > 2) {
        let haveFilter = false;
        queryPath = queryPath
          ?.split("?")
          .filter((str) => {
            const isFilter = str?.includes("filter");

            const toAdd = str === "" || (isFilter && !haveFilter);
            if (isFilter) haveFilter = true;
            return toAdd;
          })
          .join("?");

        routeProps.history.replace({ search: queryPath });
        return true;
      }
      return false;
    };

    const orderFilterDefault = [
      "pol",
      "avalible",
      "tipProdukta",
      "proizvoditelTehniki",
      "brend",
      "model",
      "perechenRazmerov",
      "spec",
      "page",
    ];

    useEffect(() => {
      setTimeout(() => {
        Loadable.preloadAll();
      }, 1000);
    }, []);

    const salesHTML = sales?.map((sale) => (
      <div className="">
        <SaleCard sale={sale} />
      </div>
    ));

    const prodsHTML = useMemo(
      () =>
        products.map((prod, i) => {
          prod.imya = decodeString(prod.imya);

          return razdel === "zapchasti" ||
            saleData?.zapchasti ||
            landing?.zapchasti ? (
            <div
              className="col col-12 col-s-12 prod-line-col p-0"
              key={String(prod._id)}
            >
              <ProdList prod={prod} />
            </div>
          ) : (
            <div
              className="col col-4 col-s-6 col-m-6 prod-col three-line"
              key={String(prod._id)}
            >
              <ProdCard
                prod={prod}
                inSalePage={sale && !isDiscountedKit}
                inDiscountedKitPage={isDiscountedKit}
                avalibleFilter={activeFilters.avalible}
              />
            </div>
          );
        }),
      [products]
    );

    let categPath = null;

    if (point && subrazvel) {
      categPath = `/catalog${
        store.slugToPath[point.toLowerCase() + subrazvel.toLowerCase()]
      }`;
    } else if (subrazvel && razdel) {
      categPath = `/catalog${
        store.slugToPath[subrazvel.toLowerCase() + razdel.toLowerCase()]
      }`;
    } else if (razdel && store.slugToNameCatalog[razdel.toLowerCase()]) {
      categPath = `/catalog/${razdel.toLowerCase()}`;
    } else if (
      location.pathname === "/catalog" ||
      location.pathname === "/catalog/"
    ) {
      categPath = `/catalog`;
    } else if (
      location.pathname === "/catalog/podarochnye-karty" ||
      location.pathname === "/catalog/podarochnye-karty/"
    ) {
      categPath = `/catalog/podarochnye-karty`;
    } else if (sale && !saleSlug) {
      categPath = `/sale`;
    } else if (brandName) {
      categPath = `/brand/${brandName}`;
    }

    const serviceSeo = store.serviceSEO[location.pathname];

    const seo = {};

    const slugToSEOKey = point
      ? subrazvel + point
      : subrazvel
      ? razdel + subrazvel
      : razdel
      ? razdel
      : generateSlug
      ? generateSlug
      : brandName;

    if (slugToSEOKey && slugToSEO[slugToSEOKey]?.title) {
      seo.title = slugToSEO[slugToSEOKey]?.title;
    } else if (serviceSeo?.title) {
      seo.title = serviceSeo?.title;
    } else {
      seo.title = header;
      if (point && subrazvel && store.slugToNameCatalog[subrazvel])
        seo.title += ` в разделе ${store.slugToNameCatalog[subrazvel]}`;
      else if (subrazvel && razdel && store.slugToNameCatalog[razdel])
        seo.title += ` в разделе ${store.slugToNameCatalog[razdel]}`;
    }

    const activeFiltersBlocks = useActiveFilters(activeFilters, changeQueryURL);

    const saleDescription = useMemo(
      () => processEditorJsHTML(saleData?.description?.html),
      [saleData?.description?.html]
    );

    const breadcrumbs = useBreadcrumbs({
      generateSlug,
      catalogFetch,
      store,
      razdel,
      subrazvel,
      point,
      saleData,
      brandName,
      header,
      search,
      sale,
      saleSlug,
      landing,
    });

    const saleDescriptionHTML = useMemo(() => {
      if (!(!sale || saleSlug)) return null;

      const Container = description?.length && description[2] ? "h2" : "h1";

      return (
        <>
          <Container
            className={`catalog__head-title ${
              catalogFetch && !saleSlug ? "mt50" : ""
            }`}
          >
            {header}
          </Container>

          {saleDescription ? (
            <div
              className="catalog__head-description"
              dangerouslySetInnerHTML={{
                __html: saleDescription,
              }}
            ></div>
          ) : null}
        </>
      );
    }, [sale, saleSlug, description, saleDescription, header]);

    return (
      <div className="catalog">
        {categPath ? (
          <link rel="canonical" href={`https://motorfirst.ru${categPath}`} />
        ) : null}
        {catalogIsLoad && (
          <Helmet
            title={`${seo.title}${
              +page && +page > 1 ? `, страница: ${+page}` : ""
            }`}
            meta={[
              {
                name: "description",
                content:
                  slugToSEO[slugToSEOKey]?.description ||
                  serviceSeo?.description ||
                  `${header} в магазине Motorfirst`,
              },
              slugToSEO[slugToSEOKey]?.keywords
                ? {
                    name: "keywords",
                    content: slugToSEO[slugToSEOKey]?.keywords,
                  }
                : serviceSeo?.keywords
                ? {
                    name: "keywords",
                    content: serviceSeo?.keywords,
                  }
                : {},

              {
                property: "og:title",
                content: `${
                  slugToSEO[slugToSEOKey]?.title || serviceSeo?.title || header
                }${+page && +page > 1 ? `, страница: ${+page}` : ""}`,
              },
              {
                property: "og:description",
                content:
                  slugToSEO[slugToSEOKey]?.description ||
                  serviceSeo?.description ||
                  `${header} в магазине Motorfirst`,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:site_name",
                content: "Motorfirst",
              },
              {
                property: "og:url",
                content: window.location.href,
              },
            ]}
          />
        )}
        {/* {mobileFiltersOpen ? (
          <MobileFilters
            closeMobileFilters={setMobileFiltersOpen}
            setNoScroll={setNoScroll}
          />
        ) : null} */}

        {sale && !saleSlug ? (
          <div className="catalog-sale__head">
            <div className="container">
              {breadcrumbs}
              <h1 className="catalog-sale__title">Акции</h1>
              {salesHTML?.length > 0 && (
                <Slider
                  {...settings}
                  ref={(slider) => (slider ? slider.slickGoTo(0) : null)}
                  className="mb50"
                >
                  {salesHTML}
                </Slider>
              )}
            </div>
          </div>
        ) : (
          breadcrumbs
        )}
        <div className="container">
          {/* {(!isMobile || !sale || saleSlug) && <div className="row"></div>} */}

          <div className={`row `}>
            <Filters
              clearFilter={clearFilter}
              setActiveFilters={setActiveFilters}
              activeFilters={activeFilters}
              catInProds={catalogFetch}
              categForFilter={categoriesForFilter}
              setCategForFilter={setCategoriesForFilter}
              changeQueryURL={changeQueryURL}
              dirtyFilters={dirtyFilters}
              inSalePage={razdel === "sale"}
              sale={sale}
              search={search}
              changePage={changePage}
              setLastFilterPointChoose={setLastChoosenFilterKey}
              harFilter={propertyFilter}
              brandName={brandName}
              razdel={razdel}
              saleSlug={saleSlug}
              orderUseFilter={orderUseFilter}
              setOrderUseFilter={setOrderUseFilter}
              setParams={setParams}
            />

            <div
              className="col col-9 col-s-12"
              // style={{ transition: "height 0.3s ease-in-out" }}
            >
              {(!isMobile || !sale || saleSlug) && (
                <div
                  className="catalog__head-line"
                  style={{
                    marginTop:
                      dontCategInTop && razdel !== "podarochnye-karty"
                        ? "50px"
                        : "",
                  }}
                >
                  {saleDescriptionHTML}
                  {(categoriesOnTop?.length > 0 || landingOnTop?.length > 0) &&
                    !generateSlug && (
                      <div className="catalog__head">
                        <div className="catalog__head-list">
                          {landingOnTop}
                          {categoriesOnTop}
                        </div>
                      </div>
                    )}
                  {!isMobile && (
                    <div className="catalog__head-filter">
                      <Drop
                        options={options}
                        def={
                          activeSort ? activeSort : { name: "Выбор Motorfirst" }
                        }
                        onChangeFunc={(e) => {
                          setActiveSort(e);
                        }}
                      />
                      {activeFiltersBlocks}
                    </div>
                  )}
                </div>
              )}
              {search ? (
                <div className="search-result__search">
                  <form
                    className="search-result__form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setSearch(
                        e.currentTarget.querySelector(".search-result__input")
                          .value
                      );
                    }}
                  >
                    <input
                      className="search-result__input"
                      type="text"
                      placeholder="Название/Артикул/OEM"
                      defaultValue={searchString}
                    />
                    <button className="search-result__btn" type="submit">
                      <i className="i-search" />
                    </button>
                  </form>
                </div>
              ) : null}
              {isMobile && activeFiltersBlocks.length > 0 ? (
                <div className="catalog__head-filter">
                  {activeFiltersBlocks}
                </div>
              ) : null}
              {isMobile ? (
                <div className="catalog__filters">
                  <button
                    className="catalog__filters-btn"
                    onClick={() => {
                      document.querySelector("body").classList.add("no-scroll");
                      document
                        .querySelectorAll(".filter__item-head")
                        .forEach((el) => el.classList.remove("open"));

                      document
                        .querySelector(".filter")
                        .classList.remove("close");
                    }}
                  >
                    Фильтры
                    <i className="i-filter" />
                  </button>
                  <Drop
                    options={options}
                    def={activeSort ? activeSort : { name: "Выбор Motorfirst" }}
                    onChangeFunc={(e) => {
                      setActiveSort(e);
                    }}
                  />
                </div>
              ) : null}

              <div className="catalog__cards">
                <LoadingOverlay isLoading={isFetching} left={-5} />
                <div className="row m-0" style={{ width: "100%" }}>
                  {!prodsHTML?.length && !isFetching ? (
                    <EmptyCatalog
                      haveLastChoosenFilter={!!lastChoosenFilterKey}
                      onCancel={() => {
                        updateFilter({
                          [lastChoosenFilterKey]: undefined,
                        });
                      }}
                    />
                  ) : (
                    prodsHTML
                  )}
                </div>
              </div>
              {dirtyFilters.count && dirtyFilters.count / 45 > 1 && (
                <Pagination
                  page={+page}
                  changePage={changePage}
                  count={dirtyFilters.count}
                  routeProps={routeProps}
                />
              )}
            </div>
          </div>
          {description?.length > 0 && (
            <div className="row">
              <div className="col col-3 col-s-0"></div>
              <div className="col col-9 col-s-12">
                <div
                  className="catalog__descr rich-text"
                  // dangerouslySetInnerHTML={{ __html: categDescr }}
                >
                  {descriptionPicture ? (
                    <img
                      src={descriptionPicture}
                      alt={`logotip ${brandName}`}
                      height="30"
                      className="mb20"
                    ></img>
                  ) : null}
                  {description[0]}
                  {/* <h2 className="catalog__descr-title">Шлем для снегохода</h2>
                <p className="catalog__descr-info">
                  Шлем — обязательный атрибут снегоходной экипировки, который
                  уберегает голову и лицо от травм, от продувания встречным
                  ветром и от летящего из под снегохода снега, природных осадков
                  и низких температур. Качественный шлем должен хорошо
                  вентилироваться и выводить избыточную влагу наружу.
                </p> */}
                  {/* {categDescr} */}

                  {description?.[1]?.length ? (
                    <p
                      className={
                        isMobile
                          ? "catalog__descr-more catalog__descr-more-mobile"
                          : "catalog__descr-more"
                      }
                      onClick={(e) => {
                        document
                          .querySelector(".catalog__descr_more")
                          .classList.toggle("active");
                        e.currentTarget.classList.toggle("active");
                      }}
                    >
                      <span>Подробнее</span>
                      <i className="i-down" />
                    </p>
                  ) : null}

                  <div className="catalog__descr_more">{description[1]}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(Catalog);
