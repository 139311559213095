// import { scan } from "react-scan";
import React from "react";
import ReactDOM from "react-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./App";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "./fonts.css";
import "./i-gift.css";
import sbjs from "sourcebuster";
import { RedLineProvider } from "./providers/RedLine/RedLineProvider";
import ErrorBoundary from "./ErrorBoundary";

try {
  sbjs.init({
    organics: [
      {
        host: "yandex.ru",
        param: "ysclid",
        display: "yandex",
      },
    ],
  });
} catch (err) {
  console.error("err :>> ", err);
}

const queryClient = new QueryClient();

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <RedLineProvider>
              <App />
            </RedLineProvider>
          </QueryParamProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </Router>
  </ErrorBoundary>,
  document.getElementById("root")
);

reportWebVitals();
