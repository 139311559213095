import React, { useMemo } from "react";
import loader from "../../img/icons/loader.svg";

/**
 * @typedef {Object} LoadingOverlayProps
 * @property {boolean} isLoading
 * @property {number} [left]
 * @property {number} [top]
 * @property {number} [right]
 * @property {number} [bottom]
 */

/**
 * @param {LoadingOverlayProps} props
 */
export const LoadingOverlay = ({ isLoading, left, top, right, bottom }) => {
  const style = useMemo(() => {
    return {
      left,
      top,
      right,
      bottom,
    };
  }, [left, top, right, bottom]);
  return (
    <div
      className={`loading-overlay ${isLoading ? "visible" : ""}`}
      style={style}
    >
      <div className={`loading-overlay__overlay`}></div>
      {isLoading && (
        <img className={`loading-overlay__loader`} src={loader} alt="loader" />
      )}
    </div>
  );
};
