import React from "react";

export const EmptyCatalog = ({ haveLastChoosenFilter, onCancel }) => {
  return (
    <div className="empty-catalog">
      <h2>Нет подходящих товаров</h2>
      <p>
        Попробуйте изменить параметры фильтрации или поиска, чтобы найти нужный
        товар.
      </p>
      {haveLastChoosenFilter && (
        <button onClick={onCancel} className="btn btn_secondary">
          Сбросить последний фильтр
        </button>
      )}
    </div>
  );
};
