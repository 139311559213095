import React from "react";
import BreadCrumb from "../../../components/Breadcrumb/BreadCrumb";

const useBreadcrumbs = ({
  generateSlug,
  catalogFetch,
  store,
  razdel,
  subrazvel,
  point,
  saleData,
  brandName,
  header,
  search,
  sale,
  saleSlug,
  landing,
}) => {
  return React.useMemo(() => {
    // Логика для лендингов
    if (landing) {
      const landingEntryPoint = JSON.parse(
        window.sessionStorage.getItem("landingEntryPoint") || "{}"
      );

      if (landingEntryPoint[landing.imya]) {
        if (
          landingEntryPoint[landing.imya].from === "brand" &&
          landing.forBrand
        ) {
          return (
            <BreadCrumb
              store={store}
              help={{
                pages: [
                  {
                    name: landing.forBrand.nazvanie,
                    path: "/brand/" + landing.forBrand.nazvanie,
                  },
                ],
              }}
              generatePage={landing}
            />
          );
        } else if (
          landingEntryPoint[landing.imya].from === "category" &&
          landing.category &&
          store.idToBreadCrumb[landing.category?._id]
        ) {
          return (
            <BreadCrumb
              store={store}
              {...store.idToBreadCrumb[landing.category._id]}
              generatePage={landing}
            />
          );
        }
      } else if (landing.category) {
        return (
          <BreadCrumb
            store={store}
            {...store.idToBreadCrumb[landing.category._id]}
            generatePage={landing}
          />
        );
      } else if (landing.forBrand) {
        return (
          <BreadCrumb
            store={store}
            help={{
              pages: [
                {
                  name: landing.forBrand.nazvanie,
                  path: "/brand/" + landing.forBrand.nazvanie,
                },
              ],
            }}
            generatePage={landing}
          />
        );
      } else {
        return <BreadCrumb store={store} generatePage={landing} />;
      }
    }

    // Хлебные крошки для акций
    if (saleData) {
      return (
        <BreadCrumb
          store={store}
          help={{
            name: "Акции",
            path: "/sale",
            pages: [{ name: saleData.imya, path: "/sale/" + saleData.slug }],
          }}
        />
      );
    }

    // Хлебные крошки для бренда
    if (brandName) {
      return (
        <BreadCrumb
          store={store}
          help={{
            pages: [{ name: header, path: "/brand/" + brandName }],
          }}
        />
      );
    }

    // Хлебные крошки для поиска и новинок
    if (search || razdel === "new") {
      return (
        <BreadCrumb
          store={store}
          razdel={razdel}
          subrazvel={subrazvel}
          point={point}
          generatePage={generateSlug}
          help={{
            pages: [],
          }}
        />
      );
    }

    // Хлебные крошки для страницы акций
    if (sale && !saleSlug) {
      return (
        <BreadCrumb
          help={{
            name: "Акции",
            path: "sale",
          }}
        />
      );
    }

    // Логика для остальных страниц
    if (!catalogFetch) {
      return (
        <BreadCrumb
          store={store}
          razdel={razdel}
          subrazvel={subrazvel}
          point={point}
        />
      );
    }

    // Дефолтные хлебные крошки
    return (
      <BreadCrumb
        store={store}
        razdel={razdel}
        subrazvel={subrazvel}
        point={point}
      />
    );
  }, [
    generateSlug,
    catalogFetch,
    store,
    razdel,
    subrazvel,
    point,
    saleData,
    brandName,
    header,
    search,
    sale,
    saleSlug,
    landing,
  ]);
};

export default useBreadcrumbs;
