import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import noImage from "../../img/noImage.png";
import { FILES_URL, sortSizes } from "../../jsCommon/constants";
import store from "../../MobX";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import api from "../../jsCommon/api";
import { observer } from "mobx-react-lite";

const charsSortArr = ["Артикул Номенклатуры", "OEM ", "Производитель техники"];

const charToHTML = (char) => {
  let value = char.znachenie;
  if (char.znachenie.includes(";")) {
    const valueArr = char.znachenie.split(";");
    if (valueArr.length === 2 && valueArr[1] === "") {
      value = valueArr[0];
    } else {
      value = valueArr.join(", ");
    }
  }
  return (
    <li className="d-flex">
      <p className="sm mr5 fw-400" style={{ color: "#717188" }}>
        {char.nazvanie}:
      </p>
      <p className="sm fw-400">{value}</p>
    </li>
  );
};

const ProdList = observer(({ prod, clickHand }) => {
  const [img, setImg] = useState(
    prod.izobrazheniya[0]
      ? FILES_URL + prod.izobrazheniya[0].formats.medium.url
      : prod.vneshnieIzobrazheniya
      ? prod.vneshnieIzobrazheniya.split(";")[0]
      : noImage
  );
  const [showAdditChar, setShowAdditChar] = useState(false);

  const sale =
      prod.cena === prod.cenaSoSkidkoy
        ? null
        : `${Math.round(100 - (prod.cenaSoSkidkoy / prod.cena) * 100)}`,
    price = prod.cena === prod.cenaSoSkidkoy ? prod.cena : null,
    oldPrice = prod.cena === prod.cenaSoSkidkoy ? null : prod.cena,
    newPrice = prod.cena === prod.cenaSoSkidkoy ? null : prod.cenaSoSkidkoy,
    name = prod.imya,
    news = prod.novyy ? true : false;

  const harakteristiki = [];
  if (prod.harakteristiki && prod.harakteristiki.length) {
    prod.harakteristiki.forEach((har) => {
      if (har.znachenie !== "required") harakteristiki.push(har);
    });
  }
  if (prod?.oem) {
    harakteristiki.push({
      nazvanie: "Номер OEM",
      znachenie: prod?.oem?.join?.(", "),
    });
  }
  if (harakteristiki.length)
    harakteristiki.sort(
      (a, b) =>
        charsSortArr.indexOf(b.nazvanie) - charsSortArr.indexOf(a.nazvanie)
    );

  return (
    <div className="row prod-list">
      <div className="col col-3 col-s-5">
        <div className="prod-list__img">
          <div className="prod-card__img">
            <NavLink
              className="prod-card__img-wrp"
              to={`/product/${prod.slug}`}
              onClick={() => {
                // window.scroll({ top: 0, left: 0, behavior: "smooth" });
                if (clickHand) {
                  clickHand();
                }
              }}
            >
              <LazyLoadImage
                src={img}
                effect="blur"
                onError={async () => setImg(noImage)}
              />
              <div className="prod-list__bages">
                {news ? <div className={`prod-card__news`}>новинка</div> : null}
                {sale ? <div className="prod-card__sale">{sale}%</div> : null}
              </div>
            </NavLink>
            {/* <div className="prod-card__img-wrp">
              <LazyLoadImage
                src={img}
                effect="blur"
                onError={async () => setImg(noImage)}
              />
              <div className="prod-list__bages">
                {news ? <div className={`prod-card__news`}>новинка</div> : null}
                {sale ? <div className="prod-card__sale">{sale}%</div> : null}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="col col-6 col-s-7">
        <NavLink
          className="p"
          to={`/product/${prod.slug}`}
          onClick={() => {
            // window.scroll({ top: 0, left: 0, behavior: "smooth" });
            if (clickHand) {
              clickHand();
            }
          }}
        >
          {name}
        </NavLink>
        <div className="prod-list__mobile-price">
          {oldPrice ? (
            <div className="prod-card__comparison">
              <div>
                <div className="prod-card__comparison-old">
                  {oldPrice?.toLocaleString()} ₽
                </div>
                <div className="prod-card__comparison-new">
                  {newPrice?.toLocaleString()} ₽
                </div>
              </div>
            </div>
          ) : (
            <div className="prod-card__price">{price?.toLocaleString()} ₽</div>
          )}
        </div>
        <div className="prod-list__bages col-s-0">
          {news ? <div className={`prod-card__news`}>новинка</div> : null}
        </div>
        {harakteristiki !== undefined && (
          <>
            <div className="prod-list__har active">
              <ul>{harakteristiki.slice(0, 3).map(charToHTML)}</ul>
            </div>

            {harakteristiki.length > 3 && (
              <a
                className={`prod-list__har-btn ${
                  showAdditChar ? "active" : ""
                }`}
                onClick={() => setShowAdditChar((s) => !s)}
              >
                <p className="sm">Характеристики</p>
                <i className="i-down sm" />
              </a>
            )}

            <div
              className={`prod-list__har addit ${
                showAdditChar ? "active" : ""
              }`}
            >
              <ul>
                {harakteristiki.slice(3, harakteristiki.length).map(charToHTML)}
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="col col-3 col-s-0 prod-list__price">
        {oldPrice ? (
          <div className="prod-card__comparison">
            <div>
              <div className="prod-card__comparison-old">
                {oldPrice?.toLocaleString()} ₽
              </div>
              <div className="prod-card__comparison-new">
                {newPrice?.toLocaleString()} ₽
              </div>
            </div>
            {sale ? <p className="prod-card__sale">{sale}%</p> : null}
          </div>
        ) : (
          <div className="prod-card__price">{price?.toLocaleString()} ₽</div>
        )}
        <div className="prod-list__btn-buy">
          <div
            className={`input--amount ${
              store.prodInCart[prod.razmery[0].productcode] !== undefined
                ? "active"
                : ""
            }`}
          >
            <button
              className="prod-descr__cart-amount-plus i-plus"
              onClick={() => {
                if (
                  store.prodInCart[prod.razmery[0].productcode].countInCart <
                  100
                )
                  store.prodInCart[prod.razmery[0].productcode].countInCart++;
              }}
            ></button>
            <span className="input--amount__input">
              {store.prodInCart[prod.razmery[0].productcode] !== undefined
                ? store.prodInCart[prod.razmery[0].productcode].countInCart
                : 1}
            </span>
            <button
              className={`prod-descr__cart-amount-minus i-minus ${
                store.prodInCart[prod.razmery[0].productcode] !== undefined &&
                store.prodInCart[prod.razmery[0].productcode].countInCart > 1
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                if (
                  store.prodInCart[prod.razmery[0].productcode].countInCart > 1
                ) {
                  store.prodInCart[prod.razmery[0].productcode].countInCart--;
                }
              }}
            ></button>
          </div>

          <button
            type="submit"
            className="btn btn_primary btn_full"
            onClick={async (e) => {
              // e.currentTarget.classList.add("deactive");
              const { isMobile } = store;
              api
                .getProductSizes([prod._id])
                .then((result) => {
                  const activeRazmer = result[0];
                  if (!isMobile) store.showCartModal = true;
                  // setTimeout(() => (store.showCartModal = false), 3000);
                  if (!store.prodInCart[activeRazmer.productcode]) {
                    const razmer = { ...activeRazmer };
                    delete razmer.tovar;
                    const prodIn = { ...prod };
                    delete prodIn.tovar;

                    store.prodInCart[activeRazmer.productcode] = {
                      ...razmer,
                      gruppaPoCvetu: { ...prodIn },
                      countInCart: 1,
                    };
                  }
                  // if (isMobile) {
                  //   e.currentTarget.classList.add("success");
                  //   setTimeout(() => {
                  //     e.target.classList.remove("success");
                  //   }, 1500);
                  // }

                  try {
                    window.dataLayer.push({
                      event: "addToCart",
                      ecommerce: {
                        currencyCode: "RUB",
                        add: {
                          products: [
                            {
                              id: activeRazmer.productcode,
                              name: activeRazmer.cardtitle,
                              brand: activeRazmer.brand,
                              category: `${activeRazmer.propertygrouprus}/${activeRazmer.propertyproductcategoryrus}/${activeRazmer.propertyproductsubcategoryrus}`,
                              price: activeRazmer.pricesaleretail,
                              quantity: 1,
                            },
                          ],
                        },
                      },
                    });
                  } catch (error) {
                    console.error("error :>> ", error);
                  }
                })
                .catch((err) => {});

              // updateOverlay(true);
            }}
          >
            В корзину
          </button>
        </div>
      </div>
    </div>
  );
});

export default ProdList;
