import React, { useEffect, useMemo, useRef, useState } from "react";

const sortSize = ["S", "M", "L"];

const Size = ({ size, type, pickFilter, checkInFilter, isAvailable }) => {
  const sizeRef = useRef(null);
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState(null);

  useEffect(() => {
    const container = containerRef.current;
    const element = sizeRef.current;
    const width = element?.clientWidth;

    const adjustFontSize = () => {
      const containerWidthWithPadding = container.clientWidth - 20;
      if (!element || !container) return;
      if (width === 0) return;

      let currentFontSize = parseInt(window.getComputedStyle(element).fontSize);

      if (containerWidthWithPadding < width && currentFontSize > 5) {
        currentFontSize--;
        setFontSize(`${currentFontSize}px`);
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [size, fontSize, containerRef.current?.clientWidth]);

  return (
    <div
      ref={containerRef}
      onClick={(el) => {
        pickFilter("perechenRazmerov", size, type);
      }}
      className={
        checkInFilter(size, type)
          ? "check"
          : "" + (isAvailable ? "" : " disabled")
      }
      style={{
        overflowX: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        ref={sizeRef}
        style={{ fontSize, height: fontSize, whiteSpace: "nowrap" }}
      >
        {size}
      </p>
    </div>
  );
};

const sortSizes = (a, b) => {
  const findIndex = (size) => (sizeFromSort) => {
    if (size?.length > 1 && size[size.length - 2] !== "X") {
      size = size[size.length - 1];
    }
    const reg = new RegExp(`\d*X*${sizeFromSort}$`);
    return reg.test(size);
  };

  let isRangeA = false;
  let isRangeB = false;

  if (a.includes("-")) {
    a = a.split("-")[0];
    isRangeA = true;
  }
  if (b.includes("-")) {
    b = b.split("-")[0];
    isRangeB = true;
  }

  if (Number.isInteger(+a) && Number.isInteger(+b)) return +a - +b;
  if (Number.isInteger(+a)) return -1;
  if (Number.isInteger(+b)) return 1;

  const aIndex = sortSize.findIndex(findIndex(a));
  const bIndex = sortSize.findIndex(findIndex(b));

  if (aIndex === bIndex) {
    if (a.length === b.length) {
      if (isRangeA) return 1;
      if (isRangeB) return -1;
      return a.localeCompare(b);
    }
    if (aIndex === sortSize.length - 1) return a.length - b.length;
    return b.length - a.length;
  }

  if (aIndex === -1 && bIndex === -1) return a.localeCompare(b);
  if (aIndex === -1) return 1;
  if (bIndex === -1) return -1;
  return aIndex - bIndex;
};

const checkAvailability = (size, availableSizes, key) => {
  return availableSizes?.[key]?.includes?.(size);
};

export const FilterElementSize = ({
  sizes,
  availableSizes,
  pickFilter,
  checkInFilter,
  haveWithoutSize,
}) => {
  const haveSizes = sizes !== undefined && Object.keys(sizes).length > 0;

  const sizesHTML = useMemo(() => {
    if (!haveSizes) return null;
    return Object.keys(sizes)
      .sort()
      .map((key) => {
        return (
          sizes[key].length > 0 && (
            <div className="filter__item-size-clothes" key={key}>
              <p className="filter__item-size-title">{key}</p>
              <div className="filter__item-size-vars">
                {sizes[key]
                  .sort(sortSizes)
                  .sort(
                    (a, b) =>
                      checkAvailability(a, availableSizes, key) -
                      checkAvailability(b, availableSizes, key)
                  )
                  .map((size) => (
                    <Size
                      size={size}
                      isAvailable={checkAvailability(size, availableSizes, key)}
                      type={key}
                      pickFilter={pickFilter}
                      checkInFilter={checkInFilter}
                      key={size}
                    />
                  ))
                  .filter(Boolean)}
              </div>
            </div>
          )
        );
      });
  }, [sizes, availableSizes, pickFilter, checkInFilter]);
  return (
    <div className="filter__item-size">
      {sizesHTML}

      {haveWithoutSize && (
        <div className="filter__item-size-none">
          <input
            type="checkbox"
            id="no-size"
            checked={checkInFilter("perechenRazmerov", "Без размера")}
            onChange={() => {
              pickFilter("perechenRazmerov", "Без размера");
            }}
          />
          <label htmlFor="no-size">Без размера</label>
        </div>
      )}
    </div>
  );
};
