import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import CartModal from "../CartModal/CartModal";

import store from "../../MobX";
import api from "../../jsCommon/api";
import { observer } from "mobx-react-lite";
import SimpleBar from "simplebar-react";
import { useQueryClient } from "@tanstack/react-query";
const NavList = observer(
  ({
    name,
    childs,
    slug,
    justLink,
    showDropdown,
    setShowDropdown,
    i,
    isCatalog = true,
  }) => {
    const [subMenu, setSubMenu] = useState([]);
    const [subMenuSlug, setSubMenuSlug] = useState("");
    const [showCross, setShowCross] = useState(false);

    const history = useHistory();
    const clickTimeout = useRef(null);

    const haveChilds =
      !justLink &&
      childs.some((cat) => cat.childs !== undefined && cat.childs.length);

    const allHaveChilds =
      !justLink &&
      childs.every((cat) => cat.childs !== undefined && cat.childs.length);

    const linksForSmallMenu = childs.map((el) => {
      return (
        <NavLink
          to={
            el.justLink
              ? `/${el.slug}`
              : `${isCatalog ? "/catalog" : ""}/${slug}/${el.slug}`
          }
          onClick={() => {
            setShowDropdown("");
          }}
          style={{ display: "block" }}
          key={el.slug}
        >
          {el.name}
        </NavLink>
      );
    });

    const openSubmenu = () => {
      setShowDropdown(name === showDropdown ? "" : name);
      store.showCartModal = false;
    };

    return justLink ? (
      <li className="navigation__left-item">
        <NavLink to={`/${slug}`} className="navigation__left-item-link special">
          {name}
        </NavLink>
      </li>
    ) : (
      <li className="navigation__left-item">
        <div
          className={`navigation__left-item--wrp ${
            name === "Экипировка" ? "btn btn_primary btn--menu" : ""
          }  `}
          onClick={(e) => {
            if (e.detail === 1) {
              if (clickTimeout.current) {
                clearTimeout(clickTimeout.current);
              }
              clickTimeout.current = setTimeout(() => {
                openSubmenu();
              }, 200);
            } else if (e.detail === 2) {
              if (clickTimeout.current) {
                clearTimeout(clickTimeout.current);
                clickTimeout.current = null;
              }
              if (slug === "brand") return;
              history.push("/catalog/" + slug);

              if (window.getSelection) {
                if (window.getSelection().empty) {
                  // Chrome
                  window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {
                  // Firefox
                  window.getSelection().removeAllRanges();
                }
              }
              if (showDropdown) setShowDropdown("");
            }
          }}
        >
          {showDropdown === "Экипировка" && name === "Экипировка" ? (
            <i className="i-close" />
          ) : (
            <span className="navigation__left-item-link">{name}</span>
          )}
        </div>

        <div
          className={`navigation__left-dropdown 
        ${haveChilds ? "navigation__left-dropdown--eq" : ""} 
        ${showDropdown === name ? "open" : ""} 
        `}
        >
          {haveChilds ? (
            <div className="navigation__left-dropdown-container">
              {/*<div className="navigation__left-dropdown-main">*/}
              <SimpleBar
                style={{
                  maxHeight: 450,
                }}
                autoHide={false}
                className="navigation__left-dropdown-main"
              >
                {childs.map((subItem, i) => {
                  return (
                    <NavLink
                      className={`navigation__left-dropdown-link ${
                        i == 0 ? "active" : ""
                      }`}
                      to={
                        subItem.justLink
                          ? `/${subItem.slug}`
                          : `/catalog/${slug}/${subItem.slug}`
                      }
                      onPointerEnter={(link) => {
                        document
                          .querySelectorAll(".navigation__left-dropdown-link")
                          .forEach((e) => {
                            e.classList.remove("active");
                          });
                        link.target.classList.add("active");
                        if (subItem.childs && subItem.childs.length) {
                          setSubMenu(subItem.childs);
                          setSubMenuSlug(subItem.slug);
                        } else if (!subMenu?.length || subMenuSlug !== "") {
                          setSubMenu([]);
                          setSubMenuSlug("");
                        }
                      }}
                      onClick={() => {
                        setShowDropdown("");
                        store.showCartModal = false;
                      }}
                      key={subItem.slug}
                      style={{ display: "block" }}
                    >
                      {subItem.name}
                    </NavLink>
                  );
                })}
              </SimpleBar>
              {/*</div>*/}
              {/*<div*/}
              {/*  className={`navigation__left-dropdown-submenu ${*/}
              {/*    subMenu.length === 0 && !allHaveChilds ? "disable" : ""*/}
              {/*  }`}*/}
              {/*>*/}
              <SimpleBar
                style={{
                  maxHeight: 450,
                }}
                autoHide={false}
                className={`navigation__left-dropdown-submenu ${
                  subMenu.length === 0 && !allHaveChilds ? "disable" : ""
                }`}
              >
                {subMenu.map((sublink, i) => {
                  return (
                    <NavLink
                      to={
                        sublink.justLink
                          ? `/${sublink.slug}`
                          : `/catalog/${slug}/${subMenuSlug}/${sublink.slug}`
                      }
                      onClick={() => {
                        setShowDropdown("");
                      }}
                      key={sublink.slug}
                      style={{ display: "block" }}
                    >
                      {sublink.name}
                    </NavLink>
                  );
                })}
              </SimpleBar>
              {/*</div>*/}
            </div>
          ) : (
            <div
              className={`navigation__left-dropdown-submenu navigation__left-dropdown--small`}
            >
              <div className="triangle"></div>

              {name === "Бренды" ? (
                <SimpleBar
                  style={{
                    maxHeight: 300,
                  }}
                  autoHide={false}
                >
                  {linksForSmallMenu}
                </SimpleBar>
              ) : (
                linksForSmallMenu
              )}
            </div>
          )}
        </div>
      </li>
    );
  }
);

// const NavList = observer(({ name, childs, showDropdown, setShowDropdown }) => {
//   // const [showCross, setShowCross] = useState(false); //креcтик у экипировки
//   const [showAll, setShowAll] = useState(false);

//   return (
//     <>
//       <NavItem
//         name={name}
//         childs={childs}
//         showAll={setShowAll}
//         showDropdown={showDropdown}
//         setShowDropdown={setShowDropdown}
//       />
//     </>
//   );
// });

// const createCategData = (elem)=>{

//   if (elem.brand)
//   slugToDataSort[elem.slug] = {
//     ...elem,
//   };
// if (elem.harFilter) slugToHarFilter[elem.slug] = elem.harFilter.name;
// dataCatalog[elem.slug] = {};
// slugToName[elem.slug] = elem.name;
// nameToSlug[elem.name] = elem.slug;
// if (elem.seo&&elem.seo.title)
//   slugToSEO[elem.slug] = {
//    ...elem.seo
//   };

// }

const Navigation = observer(
  ({ setNoScroll, noScroll, mobileMenu, setMobileMenu }) => {
    const [searchIsActive, setSearchIsActive] = useState(false);
    const [showBroder, setShowBroder] = useState(false); //border у навигации
    const [showDropdown, setShowDropdown] = useState("");
    const [isNavOnTop, setIsNavOnTop] = useState(false);
    const [listerScroll, setListerScroll] = useState(false);
    const [brandsList, setBrandsList] = useState([]);
    const history = useHistory();
    const navElem = useRef(null);
    const queryClient = useQueryClient();

    const scrollHandler = function () {
      if (navElem.current) {
        setIsNavOnTop(navElem.current.getBoundingClientRect().y === 0);
        // if (position === 0 && !isNavOnTop) {
        //   setIsNavOnTop(true);
        // } else if (position > 0 && isNavOnTop) {
        //   setIsNavOnTop(false);
        // }
      }
    };

    // const [openMenuPoints, setOpenMenuPoints] = useState({});
    const { categ, showModal, isModalType, showCartModal } = store; //заполняется массив категорий из запроса .getCategories

    useEffect(() => {
      api
        .getCategories()
        .then((data) => {
          const newData = [];
          const dataCatalog = {};
          const slugToName = {};
          const slugToDataSort = {};
          const slugToId = {};
          const slugToSEO = {};
          const nameToSlug = {};
          const linkToParent = {};
          const slugToPath = {};
          const idToBreadCrumb = {};
          const nameToCatForSale = {};
          data.forEach((elem) => {
            slugToId[elem.slug] = elem._id;
            if (elem.ruchnayaSortirovka && elem.ruchnayaSortirovka.brand)
              slugToDataSort[elem.slug] = {
                ...elem.ruchnayaSortirovka,
              };

            dataCatalog[elem.slug] = {};
            slugToName[elem.slug] = elem.name;
            nameToSlug[elem.name] = elem.slug;
            if (elem.seo && elem.seo.title)
              slugToSEO[elem.slug] = {
                ...elem.seo,
              };

            idToBreadCrumb[elem._id] = {
              razdel: elem.slug,
            };
            // if (elem.fetchName) slugToNameFetch[elem.slug] = elem.fetchName;
            newData.push(elem);
            if (elem.childs && elem.childs.length) {
              elem.childs.forEach((child) => {
                if (
                  child.ruchnayaSortirovka &&
                  child.ruchnayaSortirovka.brand &&
                  child.ruchnayaSortirovka.brand.length
                )
                  slugToDataSort[elem.slug + child.slug] = {
                    ...child.ruchnayaSortirovka,
                  };

                // if (child.fetchName && !slugToNameFetch[child.slug])
                //   slugToNameFetch[child.slug] = child.fetchName;
                // if (child.childs&&child.childs.length) {
                slugToName[child.slug] = child.name;
                nameToSlug[child.name] = child.slug;
                if (child.seo && child.seo.title)
                  slugToSEO[elem.slug + child.slug] = {
                    ...child.seo,
                  };
                // + elem.fetchName
                linkToParent[child.name + elem.name] = {
                  slug: child.slug,
                  name: child.name,
                  parent: { name: elem.name, slug: elem.slug },
                };
                slugToId[child.slug + elem.slug] = child._id;

                idToBreadCrumb[child._id] = {
                  razdel: elem.slug,
                  subrazvel: child.slug,
                };
                slugToPath[
                  child.slug + elem.slug
                ] = `/${elem.slug}/${child.slug}`;
                slugToName[child.slug] = child.name;

                if (child.childs && child.childs.length)
                  dataCatalog[elem.slug][child.slug] = child.childs.map(
                    (point) => {
                      if (
                        point.ruchnayaSortirovka &&
                        ((point.ruchnayaSortirovka.brand &&
                          point.ruchnayaSortirovka.brand.length) ||
                          (point.ruchnayaSortirovka.model &&
                            point.ruchnayaSortirovka.model.length) ||
                          point.ruchnayaSortirovka.price)
                      )
                        slugToDataSort[child.slug + point.slug] = {
                          ...point.ruchnayaSortirovka,
                        };
                      if (point.seo)
                        slugToSEO[child.slug + point.slug] = {
                          ...point.seo,
                        };
                      slugToPath[
                        point.slug + child.slug
                      ] = `/${elem.slug}/${child.slug}/${point.slug}`;

                      slugToId[point.slug + child.slug + elem.slug] = point._id;
                      idToBreadCrumb[point._id] = {
                        razdel: elem.slug,
                        subrazvel: child.slug,
                        point: point.slug,
                      };
                      linkToParent[point.name + child.name] = {
                        slug: point.slug,
                        name: point.name,
                        parent: { name: child.name, slug: child.slug },
                      };
                      if (point.categoryForSale)
                        nameToCatForSale[point.name + child.name] =
                          point.categoryForSale;
                      slugToName[point.slug] = point.name;
                      nameToSlug[point.name] = point.slug;

                      return point.name;
                    }
                  );
                else {
                  if (child.categoryForSale)
                    nameToCatForSale[child.name + elem.name] =
                      child.categoryForSale;
                }
              });
            }
          });

          store.idToBreadCrumb = idToBreadCrumb;
          store.categ = newData;
          store.slugToNameCatalog = slugToName;
          store.slugToDataSort = slugToDataSort;
          store.slugToSEO = { ...store.slugToSEO, ...slugToSEO };
          store.nameToSlugCatalog = nameToSlug;
          store.slugToPath = slugToPath;
          store.slugToId = slugToId;
          store.linkToParent = linkToParent;
          store.dataCatalog = dataCatalog;
          store.nameToCatForSale = nameToCatForSale;
          store.catalogIsLoad = true;
        })
        .catch((err) => {
          console.error("err :>> ", err);
        });
      if (!listerScroll) {
        document.addEventListener("scroll", scrollHandler, false);
        setListerScroll(true);
      }
      if (!store.brands)
        api
          .getBrands()
          .then((brands) => {
            if (brands.length) {
              const nameToSlug = {};
              setBrandsList(
                brands.map((brand) => {
                  nameToSlug[brand.nazvanie] = brand.nazvanie;
                  queryClient.setQueryData(["brand", brand.nazvanie], brand);
                  return {
                    name: brand.nazvanie,
                    slug: brand.nazvanie,
                  };
                })
              );
              store.brandsNameToSlug = nameToSlug;
              store.brands = brands;
            }
          })
          .catch((err) => console.error("err", err));
      else
        setBrandsList(
          store.brands.map((brand) => ({
            name: brand.nazvanie,
            slug: brand.nazvanie,
          }))
        );

      api
        .getGeneratePages()
        .then((result) => {
          const idCatToLanding = {};
          const slugBrandToLanding = {};
          const slugToLanding = {};

          if (result)
            result.forEach((landing) => {
              queryClient.setQueryData(["landing", landing.slag], landing);
              slugToLanding[landing.slag] = { ...landing };

              if (landing.category) {
                // Move logic slugCatToLanding to mobx

                if (!idCatToLanding[landing.category._id])
                  idCatToLanding[landing.category._id] = [];
                idCatToLanding[landing.category._id].push({ ...landing });
              }
              if (landing.forBrand) {
                if (!slugBrandToLanding[landing.forBrand.nazvanie])
                  slugBrandToLanding[landing.forBrand.nazvanie] = [];
                slugBrandToLanding[landing.forBrand.nazvanie].push({
                  ...landing,
                });
              }
            });

          store.idCatToLanding = idCatToLanding;
          store.slugBrandToLanding = slugBrandToLanding;
          store.slugToLanding = slugToLanding;
        })
        .catch((err) => console.error("err", err));

      api
        .getServiceSEO()
        .then((seo) => {
          if (seo && seo.length) {
            const pathToSeo = {};
            seo.forEach((settings) => {
              pathToSeo[settings.path] = settings.seo;
              pathToSeo[settings.path + "/"] = pathToSeo[settings.path];
            });

            store.serviceSEO = pathToSeo;
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    }, []);

    const isMobile = store.checkWidth[1000];

    let isMain = false; //У нижнего блока хедера внизу есть светлая полоска, которая отображается на всех, кроме главной
    if (window.location.pathname == "/") {
      isMain = true;
    }

    // document.addEventListener("click", (e) => {
    //   if (
    //     !e.target.classList.contains("navigation__left-dropdown-link") &&
    //     !e.target.classList.contains("navigation__left-dropdown") &&
    //     !e.target.classList.contains("navigation__left-dropdown-submenu") &&
    //     showDropdown
    //   ) {
    //     setShowDropdown(false);
    //   }
    // });

    let zero = window.pageYOffset; //У нижнего блока хедера внизу есть светлая полоска, которая отображается только при скролле вниз на главной
    // if (isMain) {
    //   window.addEventListener("scroll", () => {
    //     const newPosition = window.pageYOffset;
    //     if (zero < newPosition) {
    //       setShowBroder(true);
    //     } else {
    //       setShowBroder(false);
    //     }
    //     zero = newPosition;
    //   });
    // }

    let cartLength = store.prodInCart
      ? Object.keys(store.prodInCart).length
      : 0;

    const categories = useMemo(() => {
      return categ.map((categItem, i) => (
        <NavList
          name={categItem.name}
          childs={categItem.childs}
          slug={categItem.slug}
          justLink={categItem.justLink}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          i={i}
        />
      ));
    }, [categ, showDropdown, setShowDropdown]);

    const brands = useMemo(() => {
      if (!brandsList.length) return null;
      return (
        <NavList
          name={"Бренды"}
          childs={brandsList}
          slug="brand"
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          isCatalog={false}
        />
      );
    }, [brandsList, showDropdown, setShowDropdown]);

    return (
      <>
        {isMobile && noScroll && mobileMenu ? (
          <MobileMenu
            categ={categ}
            setMobileMenu={setMobileMenu}
            setNoScroll={setNoScroll}
            noScroll={noScroll}
            brandsList={brandsList}
          />
        ) : null}
        <div
          className={`fake-overlay ${
            showDropdown !== "" ||
            store.showCartModal ||
            store.isOpenDrop ||
            searchIsActive
              ? "active"
              : ""
          } ${store.showCartModal && "cart"}`}
          onClick={() => {
            setShowDropdown("");
            store.showCartModal = false;
            store.funcClickOnFakeOverlay();
            setSearchIsActive(false);
          }}
        />
        <nav
          className={`navigation ${showBroder ? "show-border" : ""} ${
            isMain ? "" : "show-border"
          } ${isNavOnTop ? "on-top" : ""}`}
          ref={navElem}
        >
          <div className="container navigation__container">
            <div className="navigation__left">
              <ul className="navigation__left-list">
                <a className="navigation__left-logo" href="/">
                  <i className="i-logo" />
                </a>
                {categories}
                {/* <li className="navigation__left-item">
                  <div>
                    <span href="#" className="navigation__left-item-link">
                      Бренды
                    </span>
                  </div>
                </li> */}
                {brands}

                <li className="navigation__left-item">
                  <NavLink
                    to="/sale"
                    className="navigation__left-item-link special"
                  >
                    Акции
                  </NavLink>
                </li>
                {/*<li className="navigation__left-item">*/}
                {/*  <div>*/}
                {/*    <NavLink*/}
                {/*      to="/catalog/podarochnye-karty"*/}
                {/*      className="navigation__left-item-link icon-gift"*/}
                {/*    ></NavLink>*/}
                {/*  </div>*/}
                {/*</li>*/}
                {searchIsActive ? (
                  <div className="navigation__desktop-search">
                    <form
                      className="navigation__desktop-form"
                      onSubmit={(e) => {
                        e.preventDefault();

                        history.push(
                          "/search?search=" +
                            e.currentTarget.querySelector(
                              ".navigation__desktop-input"
                            ).value
                        );
                      }}
                    >
                      <input
                        className="navigation__desktop-input"
                        placeholder="Название/Артикул/OEM"
                        type="text"
                      />
                      <button className="navigation__desktop-btn" type="submit">
                        <i className="i-search" />
                      </button>
                    </form>
                  </div>
                ) : (
                  <></>
                )}
              </ul>
            </div>

            <div className="navigation__right">
              <a
                className="navigation__right-icon search"
                onClick={() => {
                  setSearchIsActive(!searchIsActive);
                }}
              >
                {searchIsActive ? (
                  <i className="i-close" />
                ) : (
                  <i className="i-search" />
                )}
              </a>

              {store.userData !== null ? (
                <NavLink to="/profile" className="navigation__right-icon user">
                  <i className="i-user" />
                </NavLink>
              ) : (
                <a
                  className="navigation__right-icon user"
                  onClick={() => {
                    store.showModal = true;
                    store.isModalType = { auth: true };
                  }}
                >
                  <i className="i-user" />
                </a>
              )}

              {cartLength > 0 && (
                <NavLink to="/cart" className="navigation__right-icon cart-btn">
                  <i className="i-cart-off" />
                  <span className="cart-btn__counter">
                    {cartLength > 9 ? 9 : cartLength}
                  </span>
                </NavLink>
              )}
              {store.showCartModal ? <CartModal /> : null}
            </div>
          </div>
        </nav>
      </>
    );
  }
);

export default Navigation;
