import { useCallback, useMemo } from "react";
import {
  useQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  JsonParam,
  encodeQueryParams,
  withDefault,
} from "use-query-params";
import queryString from "query-string";

const parse = (str, additSettings = {}) => {
  return queryString.parse(str, {
    arrayFormat: "bracket",
    ignoreQueryPrefix: true,
    ...additSettings,
  });
};

export const ulrStringify = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
    if (obj[key]?.$gte || obj[key]?.$lte) {
      obj[key] = queryString.stringify([obj[key].$gte, obj[key].$lte], {
        arrayFormat: "bracket",
      });
    }
  });

  return queryString.stringify(obj, { arrayFormat: "bracket" });
};

export const ulrParse = (str) => {
  const parsed = parse(str);

  Object.keys(parsed).forEach((key) => {
    switch (key) {
      case "god":
      case "cenaSoSkidkoy":
        const arr = parse(parsed[key]);
        parsed[key] = {
          $gte: +arr[0],
          $lte: +arr[1],
        };
        break;
      case "_compatibilitiesFilter_year":
        if (Array.isArray(parsed[key])) parsed[key] = parsed[key].map(Number);
        break;
      default:
        break;
    }
  });

  return parsed;
};

/**
 * Создает кастомный параметр для фильтров, который сохраняет обратную совместимость
 * с существующим форматом URL
 * @param {Object} defaultValue - Значение по умолчанию
 * @returns {Object} - Объект параметра для use-query-params
 */
const createFilterParam = (defaultValue = {}) => {
  return {
    encode: (value = {}) => {
      // Если значение пустое, не добавляем в URL
      if (!value || Object.keys(value).length === 0) {
        return undefined;
      }

      // Преобразуем объект фильтров в строку
      return ulrStringify(value);
    },
    decode: (strValue) => {
      if (!strValue) {
        return defaultValue;
      }

      // Парсим строку фильтров в объект
      return ulrParse(strValue);
    },
  };
};

/**
 * Хук для работы с URL-параметрами с использованием use-query-params
 * @param {Object} options - Опции
 * @param {Object} [options.defaultValues={}] - Значения по умолчанию
 * @returns {Object} - Объект с параметрами и функциями для работы с ними
 */
export const useUrlParamsWithQueryParams = (options = {}) => {
  const { defaultValues = {} } = options;

  // Создаем конфигурацию параметров
  const paramConfig = useMemo(() => {
    return {
      filter: withDefault(createFilterParam(), defaultValues.filter || {}),
      page: withDefault(NumberParam, defaultValues.page || 1),
      search: withDefault(StringParam, defaultValues.search || undefined),
      sort: withDefault(StringParam, defaultValues.sort || undefined),
    };
  }, [defaultValues]);

  // Используем хук useQueryParams для работы с параметрами
  const [params, setParams] = useQueryParams(paramConfig, {
    updateType: "replaceIn",
  });

  // Функция для обновления параметров
  const updateParams = useCallback(
    (newParams) => {
      // const updatedParams = { ...params };

      // // Обрабатываем фильтры отдельно
      // if (newParams.filter) {
      //   updatedParams.filter = { ...params.filter, ...newParams.filter };

      //   // Удаляем пустые значения из фильтров
      //   Object.keys(updatedParams.filter).forEach((key) => {
      //     if (
      //       updatedParams.filter[key] === undefined ||
      //       updatedParams.filter[key] === null ||
      //       (Array.isArray(updatedParams.filter[key]) &&
      //         updatedParams.filter[key].length === 0)
      //     ) {
      //       delete updatedParams.filter[key];
      //     }
      //   });

      //   // Если изменились фильтры, сбрасываем страницу на первую
      //   if (newParams.filter && !newParams.page) {
      //     updatedParams.page = 1;
      //   }
      // }

      // // Обновляем остальные параметры
      // if (newParams.page !== undefined) updatedParams.page = newParams.page;
      // if (newParams.search !== undefined)
      //   updatedParams.search = newParams.search;
      // if (newParams.sort !== undefined) updatedParams.sort = newParams.sort;

      // // Обновляем URL
      setParams(newParams);
    },
    [setParams]
  );

  // Функция для сброса параметров
  const resetParams = useCallback(() => {
    setParams(defaultValues);
  }, [setParams, defaultValues]);

  // Функция для обновления фильтров
  const setFilter = useCallback(
    (filterName, value) => {
      setParams((params) => ({
        ...params,
        filter: { ...(params.filter || {}), [filterName]: value },
        page: 1, // При изменении фильтра сбрасываем на первую страницу
      }));
    },
    [setParams]
  );

  const updateFilter = useCallback(
    (filter) => {
      setParams((params) => ({
        ...params,
        filter: { ...(params.filter || {}), ...filter },
      }));
    },
    [setParams]
  );

  const setFilters = useCallback(
    (filters) => {
      setParams((params) => ({
        ...params,
        filter: filters,
        page: 1,
      }));
    },
    [setParams]
  );

  // Функция для сброса фильтров
  const resetFilters = useCallback(() => {
    updateParams({
      filter: defaultValues.filter || {},
      page: 1,
    });
  }, [updateParams, defaultValues]);

  // Функция для изменения страницы
  const setPage = useCallback(
    (page) => {
      setParams({ page }, "pushIn");
    },
    [setParams]
  );

  const setSearch = useCallback(
    (search) => {
      setParams({ search, page: 1, filter: {} }, "pushIn");
    },
    [setParams]
  );

  return {
    params,
    setParams,
    resetParams,
    setFilters,
    setFilter,
    resetFilters,
    setPage,
    updateFilter,
    setSearch,
  };
};

export default useUrlParamsWithQueryParams;
