import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const BreadCrumb = ({
  razdel,
  subrazvel,
  point,
  store,
  product,
  help,
  generatePage,
}) => {
  const slugToNameCatalog = store?.slugToNameCatalog;

  const [hide, setHide] = useState(false);
  const elem = useRef();

  useEffect(() => {
    if (elem.current) {
      const size = elem.current?.getBoundingClientRect();

      if (size?.height !== undefined) setHide(size.height === 0 ? true : false);
    }
  }, [elem, razdel, subrazvel, point, product, help, generatePage]);

  return (
    <div className={`breadcrumb ${hide ? "hide" : ""}`}>
      <div className="container">
        <ul
          className="breadcrumb__list"
          itemScope={true}
          itemType="https://schema.org/BreadcrumbList"
          ref={elem}
        >
          {help ? (
            <>
              {/* <li
                className="breadcrumb__item"
                itemProp="itemListElement"
                itemScope="true"
                itemtype="https://schema.org/ListItem"
              >
                <NavLink
                  to="/"
                  className="breadcrumb__link p sm"
                  exact
                  itemProp="item"
                >
                  <span itemProp="name">Главная</span>
                  <meta itemProp="position" content="1" />
                </NavLink>
              </li> */}
              {help.path && help.name && (
                <li
                  className="breadcrumb__item"
                  itemProp="itemListElement"
                  itemScope={true}
                  itemType="https://schema.org/ListItem"
                >
                  <NavLink
                    to={help.path}
                    className="breadcrumb__link p sm"
                    exact
                    itemProp="item"
                  >
                    <span itemProp="name">{help.name}</span>
                    <meta itemProp="position" content="2" />
                  </NavLink>
                </li>
              )}
              {help.pages?.length
                ? help.pages.map((page, i) => (
                    <li
                      key={i}
                      className="breadcrumb__item"
                      itemProp="itemListElement"
                      itemScope={true}
                      itemType="https://schema.org/ListItem"
                    >
                      <NavLink
                        to={page.path}
                        className="breadcrumb__link p sm"
                        exact
                        itemProp="item"
                      >
                        <span itemProp="name">{page.name}</span>
                        <meta itemProp="position" content={i + 3} />
                      </NavLink>
                    </li>
                  ))
                : null}
            </>
          ) : (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope="true"
              itemtype="https://schema.org/ListItem"
            >
              <NavLink
                to="/catalog"
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name">Каталог</span>
                <meta itemProp="position" content="1" />
              </NavLink>
            </li>
          )}
          {razdel !== undefined && (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <NavLink
                to={`/catalog/${razdel}`}
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name">{slugToNameCatalog[razdel]}</span>
                <meta itemProp="position" content="2" />
              </NavLink>
            </li>
          )}
          {subrazvel !== undefined && (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <NavLink
                to={`/catalog/${razdel}/${subrazvel}`}
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name">{slugToNameCatalog[subrazvel]}</span>
                <meta itemProp="position" content="3" />
              </NavLink>
            </li>
          )}
          {point !== undefined && (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <NavLink
                to={`/catalog/${razdel}/${subrazvel}/${point}`}
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name">{slugToNameCatalog[point]}</span>
                <meta itemProp="position" content="4" />
              </NavLink>
            </li>
          )}
          {product !== undefined && (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <NavLink
                to={`/product/${product.slug}`}
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name"> {product.imya}</span>
                <meta
                  itemProp="position"
                  content={point !== undefined ? "5" : "4"}
                />
              </NavLink>
            </li>
          )}
          {generatePage !== undefined && (
            <li
              className="breadcrumb__item"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <NavLink
                to={`/landing/${generatePage.slag}`}
                className="breadcrumb__link p sm"
                exact
                itemProp="item"
              >
                <span itemProp="name"> {generatePage.imya}</span>
                <meta
                  itemProp="position"
                  content={point !== undefined ? "5" : "4"}
                />
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default BreadCrumb;
