import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../jsCommon/api";
import store from "../../../MobX";
import Inputmask from "inputmask";

const Arrival = ({}) => {
  const [callbackEmail, setCallbackEmail] = useState(true);
  const [isSuccses, setIsSuccses] = useState(null);
  const [personal, setPersonal] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({ mode: "onChange" });

  const button = useRef(null);

  useEffect(() => {
    if (document.querySelector("#callback-phone")) {
      const tel = new Inputmask({
        mask: "+7 (999) 999 99 99",
        showMaskOnHover: false,
        // isComplete: function()
      });

      tel.mask(document.querySelector("#callback-phone"));
    }
  }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!personal) {
      return;
    }
    button.current.classList.add("deactive");

    // if (callbackEmail) {
    //   data.email = data.contact;
    // } else data.tel = data.contact;

    api
      .postCallAvailability({
        ...data,
        prodInCart: {
          [store.isModalType.prod.productcode]: store.isModalType.prod,
        },
      })
      .then((result) => {
        setIsSuccses(true);
        setTimeout(() => {
          store.showModal = false;
          store.isModalType = {};
        }, 2000);
      })
      .catch((err) => {
        setIsSuccses(false);
        console.error("err :>> ", err);
      })
      .finally(
        () => button.current && button.current.classList.remove("deactive")
      );
  };

  useEffect(() => {
    for (const key in errors) {
      errors[key].ref.classList.add("error");
    }
  }, [errors]);

  let classButton =
    isSuccses === null ? "" : isSuccses === true ? "success" : "fail";

  return (
    <>
      <form className="modal-window__form" onSubmit={handleSubmit(onSubmit)}>
        <label className="label-wrp" htmlFor="callback-name">
          Имя
          <input
            id="callback-name"
            type="text"
            {...register("name", { required: true })}
          />
        </label>

        <div className="modal-window__answer " style={{ marginTop: "5px" }}>
          {/* <p className="">Ответить на:</p>

          <label className="radio" htmlFor="radio-phone">
            <input
              className="modal-window__radio-input"
              type="radio"
              name="callback-type"
              id="radio-phone"
              onChange={() => {
                setCallbackEmail(false);
                setValue("contact", "");
              }}
            />
            Телефон
          </label>

          <label className="radio" htmlFor="radio-email">
            <input
              className="modal-window__radio-input"
              type="radio"
              name="callback-type"
              id="radio-email"
              defaultChecked
              onChange={() => {
                setCallbackEmail(true);
                setValue("contact", "");
              }}
            />
            E-mail
          </label> */}

          <label className="label-wrp mt20" htmlFor="callback-email">
            E-mail
            <input
              className="modal-window__input"
              id="callback-email"
              type="email"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
          </label>

          <label className="label-wrp mt20" htmlFor="callback-phone">
            Телефон
            <input
              className="modal-window__input"
              id="callback-phone"
              type="tel"
              {...register("tel", {
                required: true,
                pattern: /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/i,
              })}
            />
          </label>
        </div>

        <button
          className={`modal-window__btn btn btn_primary ${classButton}`}
          type="submit"
          ref={button}
        >
          Отправить
        </button>
        <input
          type="checkbox"
          id="callback-access"
          className="modal-window__checkbox-input"
          checked={personal}
          onChange={(e) => setPersonal(e.target.checked)}
        />
        <label htmlFor="callback-access">
          Я принимаю{" "}
          <a href="/service/personal-data" target="_blank" rel="noreferrer">
            "Cоглашение об обработке персональных данных"
          </a>
        </label>
      </form>
    </>
  );
};

export default Arrival;
