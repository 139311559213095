import React, { useMemo } from "react";

const useActiveFilters = (activeFilters, changeQueryURL) => {
  return useMemo(() => {
    const activeFiltersBlocks = [];

    if (activeFilters) {
      Object.keys(activeFilters).forEach((name) => {
        if (name !== "pol")
          if (Array.isArray(activeFilters[name])) {
            activeFilters[name].forEach((elem, i) => {
              let nameVariation = elem;

              if (name === "perechenRazmerov" || name === "spec")
                nameVariation = nameVariation.split("-")[1];

              activeFiltersBlocks.push(
                <div
                  className="catalog__head-filter__active"
                  key={`${name}-${i}`}
                >
                  <p>{nameVariation}</p>
                  <i
                    className="i-close"
                    onClick={() => {
                      if (activeFilters[name].length > 1)
                        activeFilters[name].splice(i, 1);
                      else delete activeFilters[name];
                      if (name === "brend" && activeFilters.model) {
                        delete activeFilters.model;
                      }
                      changeQueryURL({
                        pageChange: 1,
                        activeFiltersChange: activeFilters,
                      });
                    }}
                  ></i>
                </div>
              );
            });
          } else {
            let nameVariation = activeFilters[name];

            if (name === "god") {
              const from = activeFilters[name].$gte;
              const to = activeFilters[name].$lte;

              let str = "";
              if (from && to) {
                str = `20${from} - 20${to}`;
              } else if (from) {
                str = `от 20${from}`;
              } else if (to) {
                str = `до 20${to}`;
              }

              nameVariation = str;
            } else if (name === "cenaSoSkidkoy") {
              const from = activeFilters[name].$gte;
              const to = activeFilters[name].$lte;

              let str = "";
              if (from && to) {
                str = `${from?.toLocaleString()} - ${to?.toLocaleString()}`;
              } else if (from) {
                str = `от ${from?.toLocaleString()}`;
              } else if (to) {
                str = `до ${to?.toLocaleString()}`;
              }

              nameVariation = str;
            } else if (name === "skidka")
              nameVariation =
                activeFilters[name] === "true" ? "Со скидкой" : "";

            activeFiltersBlocks.push(
              <div className="catalog__head-filter__active" key={name}>
                <p>{nameVariation}</p>
                <i
                  className="i-close"
                  onClick={() => {
                    delete activeFilters[name];
                    changeQueryURL({
                      pageChange: 1,
                      activeFiltersChange: activeFilters,
                    });
                  }}
                ></i>
              </div>
            );
          }
      });
    }

    if (activeFiltersBlocks.length) {
      activeFiltersBlocks.push(
        <div className="catalog__head-filter__active" key="reset-filters">
          <p
            onClick={() => {
              changeQueryURL({
                pageChange: 1,
                activeFiltersChange: {
                  pol: activeFilters.pol,
                },
              });
            }}
          >
            Сбросить фильтры
          </p>
        </div>
      );
    }

    return activeFiltersBlocks;
  }, [activeFilters, changeQueryURL]);
};

export default useActiveFilters;
