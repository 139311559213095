import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { sendNotFoundPath, decodeString, redirectTo404 } from "src/funcs";
import store from "../../../MobX";
import api from "../../../jsCommon/api";
import { sortSizes } from "src/jsCommon/constants";

const BASE_QUERY_SETTINGS = {
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  cacheTime: 10 * 60 * 1000,
  staleTime: 10 * 60 * 1000,
};

const SPEC_KEYS = ["harakteristiki", "harakteristikiZapchastey"];
const CLEAR_FILTER_KEY = "clearFilter";

const processSpecFilter = (specFilter) => {
  const filter = {};

  specFilter.forEach((variant) => {
    const [nazvanie, ...value] = variant.split("-");
    if (!filter[nazvanie]) {
      filter[nazvanie] = [];
    }
    filter[nazvanie].push(
      ...SPEC_KEYS.map((key) => ({
        [key]: { $elemMatch: { nazvanie, znachenie: value.join("-") } },
      }))
    );
  });

  return Object.values(filter).map((filterSpec) => {
    return { $or: [...filterSpec] };
  });
};

const returnSortFromName = ({ name }) => {
  switch (name) {
    case "По возрастанию":
      return { cenaSoSkidkoy: 1 };
    case "По убыванию":
      return { cenaSoSkidkoy: -1 };
    case "Новинки":
      return { god: -1 };
    case "Старое":
      return { god: 1 };

    default:
      return undefined;
  }
};

/**
 * Хук для работы с запросами каталога с использованием react-query
 * @param {Object} options - Опции
 * @param {Object} [options.params={}] - Параметры запроса из URL
 * @param {Object} [options.filterByCategories=null] - Категория для фильтрации
 * @param {string} [options.razdel] - Раздел каталога
 * @param {string} [options.subrazvel] - Подраздел каталога
 * @param {string} [options.point] - Точка каталога
 * @param {boolean} [options.sale] - Флаг распродажи
 * @param {string} [options.saleSlug] - Slug распродажи
 * @param {string} [options.landingSlug] - Slug landing
 * @param {boolean} [options.catalogFetch] - Флаг загрузки каталога
 * @param {string} [options.searchString] - Строка поиска
 * @param {string} [options.brandName] - Имя бренда
 * @param {string} [options.personalUID] - UID персональной страницы
 * @param {boolean} [options.isDiscountedKit] - Флаг скидочного комплекта
 * @param {object} [options.activeSort] - Активная сортировка
 * @param {object} [options.categoriesForFilter] - Категории для фильтрации
 * @param {boolean} [options.isCategoryListRequest] - Флаг запроса списка категорий
 * @param {'pagin' | 'btn'} [options.typePageChange] - Тип изменения страницы
 * @param {string} [options.lastChoosenFilterKey] - Ключ последнего выбранного фильтра
 *
 * @param {function} updateFilter - Функция для обновления фильтра
 * @param {function} setPage - Функция для установки страницы
 * @returns {Object} - Объект с данными и функциями для работы с запросами
 */
export const useCatalogQueries = (options = {}, updateFilter, setPage) => {
  const {
    params = {},
    razdel,
    subrazvel,
    point,
    sale,
    saleSlug,
    landingSlug,
    searchString,
    brandName,
    personalUID,
    isDiscountedKit,
    activeSort,
    categoriesForFilter,
    isCategoryListRequest,
    typePageChange,
    lastChoosenFilterKey,
  } = options;

  const queryClient = useQueryClient();
  const history = useHistory();

  /**
   * Формирует базовый объект запроса на основе параметров
   * @returns {Object} Объект запроса для API
   */
  const createBaseRequest = () => {
    let filters = {};
    const { filter = {} } = params;

    // Обрабатываем фильтры из URL
    Object.keys(filter).forEach((name) => {
      if (name.startsWith("_")) {
        const [, keyObj, realName] = name.split("_");
        const key = `_${keyObj}`;

        if (!filters[key]) filters[key] = {};

        if (Array.isArray(filter[name])) {
          filters[key][realName] = { $in: filter[name] };
        } else {
          filters[key][realName] = filter[name];
        }
      } else if (
        filter[name] &&
        name !== "pol" &&
        name !== "skidka" &&
        name !== "spec" &&
        name !== "avalible" &&
        (typeof filter[name] === "string" || Array.isArray(filter[name]))
      ) {
        filters[name] = { $in: [...filter[name]] };
      } else if (name === "skidka") {
        filters[name] = filter[name] === "true";
      } else {
        filters[name] = filter[name];
      }
    });

    // Формируем объект запроса
    const dataProdReq = {
      filter: { ...filters, ...(categoriesForFilter || {}) },
      pagin: {
        limit: 45,
        skip: 45 * ((params.page || 1) - 1),
      },
      point,
      subrazvel,
      razdel,
      landingSlug,
      brandName,
      path: window.location.pathname,
      isCategoryListRequest,
      personalUID,
    };

    // Добавляем сортировку
    if (activeSort && activeSort !== "Выбор Motorfirst")
      dataProdReq.sort = returnSortFromName(activeSort);

    // Добавляем фильтр по наличию
    if (filter.avalible) {
      switch (filter.avalible) {
        case "SH":
          dataProdReq.filter.ostatokVPostavke = { $gt: 0 };
          break;
        case "WH":
          dataProdReq.filter.remainsStock = { $gt: 0 };
          break;
        default:
          break;
      }
      delete dataProdReq.filter.avalible;
    }

    // Добавляем поиск по тексту
    if (searchString) {
      if (searchString.includes(" ")) {
        dataProdReq.searchString = `/${searchString
          .split(" ")
          .map((s) => `"${s}"+`)
          .join(" ")}/gi`;
      } else {
        dataProdReq.searchString = searchString;
      }
    }

    // Обрабатываем фильтр по размерам
    if (dataProdReq.filter.perechenRazmerov) {
      const filterWithType = {};
      dataProdReq.filter.perechenRazmerov.$in.forEach((s) => {
        const size = s.match(/(?:([А-Яа-яёЁ ]+)-([0-9A-Za-z-/]+))/i);
        if (size && size[1] && size[2]) {
          filterWithType[size[1]]
            ? filterWithType[size[1]].push(size[2])
            : (filterWithType[size[1]] = [size[2]]);
        }
      });

      const razmerFilter = Object.keys(filterWithType).map((type) => ({
        tipRazmera: type,
        perechenRazmerov: {
          $regex: `^${filterWithType[type].join("|")}$`,
          $options: "im",
        },
      }));

      razmerFilter.push(
        ...Object.keys(filterWithType).map((type) => ({
          tipRazmera: type,
          perechenRazmerov: {
            $regex: `${filterWithType[type]
              .map((v) => `(${v},)|(${v} ,)|(,${v})|(, ${v})`)
              .join("|")}`,
            $options: "im",
          },
        }))
      );

      if (dataProdReq.filter.perechenRazmerov.$in.includes("Без размера")) {
        razmerFilter.push({ perechenRazmerov: { $in: ["Без размера"] } });
      }

      delete dataProdReq.filter.perechenRazmerov;

      if (dataProdReq.filter.$and) {
        const andFilter = [...dataProdReq.filter.$and];
        andFilter.push({ $or: razmerFilter });
        dataProdReq.filter.$and = andFilter;
      } else {
        dataProdReq.filter.$and = [{ $or: razmerFilter }];
      }
    }

    // Обрабатываем фильтр по производителю техники
    if (dataProdReq.filter.proizvoditelTehniki) {
      const regExProizvoditel =
        dataProdReq.filter.proizvoditelTehniki.$in.join("|");
      dataProdReq.filter.proizvoditelTehniki = {
        $regex: regExProizvoditel,
        $options: "im",
      };
    }

    // Обрабатываем фильтр по акции
    if (sale && saleSlug) {
      if (isDiscountedKit) {
        dataProdReq.filter.isDiscountedKit = true;
      } else {
        dataProdReq.saleSlug = saleSlug;
      }
    }

    // Обрабатываем фильтр по году
    if (dataProdReq.filter.god) {
      if (dataProdReq.filter.god.$gte) {
        dataProdReq.filter.god.$gte = +dataProdReq.filter.god.$gte;
      }
      if (dataProdReq.filter.god.$lte) {
        dataProdReq.filter.god.$lte = +dataProdReq.filter.god.$lte;
      }
    }

    // Обрабатываем фильтр по цене
    if (dataProdReq.filter.cena) {
      if (dataProdReq.filter.cena.$gte) {
        dataProdReq.filter.cena.$gte = +dataProdReq.filter.cena.$gte;
      }
      if (dataProdReq.filter.cena.$lte) {
        dataProdReq.filter.cena.$lte = +dataProdReq.filter.cena.$lte;
      }
    }

    // Добавляем фильтр по скидке
    if (razdel === "sale" || sale) {
      dataProdReq.filter.skidka = true;
      dataProdReq.isSalePage = true;
      if (!saleSlug) {
        dataProdReq.filter.gruppaRazdelov = {
          $nin: ["Запчасти", "Техника"],
        };
      }
    } else if (razdel === "new") {
      dataProdReq.filter.novyy = true;
    }

    if (dataProdReq.filter.spec) {
      const specFilter = processSpecFilter(dataProdReq.filter.spec);
      if (!dataProdReq.filter.$and) dataProdReq.filter.$and = [];
      dataProdReq.filter.$and.push(...specFilter);

      delete dataProdReq.filter.spec;
    }

    return dataProdReq;
  };

  const setCompatibilitiesFilter = (compatibilities, source) => {
    if (!compatibilities) return;

    Object.keys(compatibilities).forEach((key) => {
      if (key === "_id") return;
      if (compatibilities[key].length) {
        if (key === "year")
          compatibilities[key] = compatibilities[key].sort((a, b) => +b - +a);
        else compatibilities[key] = compatibilities[key].sort();
        source[`_compatibilitiesFilter_${key}`] = compatibilities[key];
      }
    });
  };

  const processFilter = ({ filter, compatibility, propertyFilter }) => {
    if (filter) {
      Object.keys(filter)?.forEach((fil) => {
        if (Array.isArray(filter[fil]) && !fil?.startsWith("_"))
          filter[fil] = filter[fil].filter(Boolean).sort();
      });

      // Сортировка массивов в filter
      Object.keys(filter)?.forEach((key) => {
        if (Array.isArray(filter[key])) filter[key] = filter[key].sort();
      });
      if (filter?.perechenRazmerov) {
        Object.keys(filter.perechenRazmerov)?.forEach((key) => {
          if (Array.isArray(filter.perechenRazmerov[key]))
            filter.perechenRazmerov[key] = filter.perechenRazmerov[key].sort();
        });
      }
    }

    if (filter.proizvoditelTehniki) {
      const proizvoditelTehVars = new Set();

      filter.proizvoditelTehniki.forEach((pt) => {
        let name = [pt];
        if (pt.includes(";") || pt.includes(",")) {
          const valueArr = pt.split(/[,;]/);
          if (valueArr.length === 2 && valueArr[1] === "") {
            name = [valueArr[0]];
          } else {
            name = valueArr;
          }
        }

        name.forEach((prVar) => {
          if (!prVar) return;
          prVar = prVar.trim();
          proizvoditelTehVars.add(prVar);
        });
      });

      filter.proizvoditelTehniki = Array.from(proizvoditelTehVars);
    }

    setCompatibilitiesFilter(compatibility, filter);

    propertyFilter?.forEach((property) => {
      if (filter[property] && Array.isArray(filter[property])) {
        filter[property] = filter[property].filter(
          (val) => val !== "" && val !== "required"
        );
      }
    });

    if (filter?.sizes) {
      filter.perechenRazmerov = {};

      filter.sizes.forEach((size) => {
        if (size.perechenRazmerov.includes("Без размера"))
          filter.haveWithoutSize = true;

        if (!size._id) return;
        filter.perechenRazmerov[size._id] = size.perechenRazmerov
          .reduce(
            (arr, size, i) => {
              if (size?.includes(",")) {
                const sizeArr = size.match(/\w+/g);

                sizeArr.forEach((s) => (!arr.includes(s) ? arr.push(s) : null));
              }
              return arr;
            },
            size.perechenRazmerov.filter(
              (s) => s && !s?.includes(",") && !s?.includes("Без размера")
            )
          )
          .sort((a, b) => +a - +b)
          .sort((a, b) => {
            return sortSizes.indexOf(a) - sortSizes.indexOf(b);
          });
      });
    }

    return filter;
  };

  const productKeys = [
    params,
    razdel,
    subrazvel,
    point,
    sale,
    saleSlug,
    searchString,
    landingSlug,
    brandName,
    personalUID,
    isDiscountedKit,
    categoriesForFilter,
    isCategoryListRequest,
    activeSort,
    typePageChange,
  ];

  /**
   * Запрос на получение продуктов
   * @type {import('@tanstack/react-query').UseQueryResult<{products: Array, filter: Object, clearFilter: Object, page: number, propertyFilter: string[]}, Error>}
   */
  const productsQuery = useQuery(
    ["products", ...productKeys],
    async () => {
      const clearFilterPrev = queryClient.getQueryData([
        CLEAR_FILTER_KEY,
        ...productKeys.slice(1),
      ]);

      const dataProdReq = createBaseRequest();

      // TODO: обработать случай, когда в фильтре есть бренд, а в clearFilter нет модели
      if (clearFilterPrev) {
        dataProdReq.needClearData = false;
      }

      let {
        products,
        filter,
        clearFilter: clearFilterFromRequest,
        requiredFilterToSet,
        compatibility,
        propertyFilter,
      } = await api.getProductsAndFilters(dataProdReq);

      if (!products?.length) {
        if (!lastChoosenFilterKey) {
          if (params.page > 1) {
            setPage(1);
          } else if (searchString) {
            history.replace(`/search/not-found?search=${searchString}`);
          } else {
            redirectTo404(history);
          }
        }

        if (productsQuery.data?.filter) delete productsQuery.data.filter.count;

        return {
          products: [],
          filter: productsQuery.data?.filter,
          clearFilter: clearFilterFromRequest || clearFilterPrev,
          propertyFilter: productsQuery.data?.propertyFilter,
          page: params.page,
        };
      }

      const clearFilter = clearFilterFromRequest || clearFilterPrev;

      if (
        productsQuery.data?.page !== params.page &&
        productsQuery.data?.products?.length &&
        typePageChange === "btn"
      ) {
        products = [...productsQuery.data.products, ...products];
      }

      processFilter({ filter, compatibility, propertyFilter });
      processFilter({ filter: clearFilter, compatibility, propertyFilter });

      if (requiredFilterToSet && Object.keys(requiredFilterToSet)?.length) {
        queryClient.setQueryData(
          [
            "products",
            {
              ...params,
              filter: {
                ...params.filter,
                ...requiredFilterToSet,
              },
            },
            ...productKeys.slice(1),
          ],
          {
            products,
            filter,
            clearFilter,
            propertyFilter,
            page: params.page,
          }
        );
      }

      if (clearFilterFromRequest) {
        queryClient.setQueryData(
          [CLEAR_FILTER_KEY, ...productKeys.slice(1)],
          clearFilterFromRequest
        );
      }

      const haveImageOrder = products[0] && products[0].izobrazheniyaArr;
      products.forEach((el, i) => {
        if (haveImageOrder && products[i].izobrazheniya.length > 1)
          products[i].izobrazheniya.sort((a, b) => {
            return (
              products[i].izobrazheniyaArr.indexOf(a._id) -
              products[i].izobrazheniyaArr.indexOf(b._id)
            );
          });
        products[i].imya = decodeString(products[i].imya);
      });

      if (razdel === "podarochnye-karty") {
        let giftPage = null;
        products = products.filter((prod) => {
          if (prod.imya === "Подарочная страница") {
            if (prod.cenaSoSkidkoy === 50000) {
              giftPage = prod;
            }
            return false;
          }
          return true;
        });
        if (giftPage) products.unshift(giftPage);
      }

      return {
        products,
        filter,
        clearFilter,
        propertyFilter,
        page: params.page,
        requiredFilterToSet,
      };
    },
    {
      ...BASE_QUERY_SETTINGS,
      // enabled: !!searchString || !!sale || !!brandName || !!personalUID,
    }
  );

  /**
   * Функция для инвалидации кэша запросов
   * @returns {void}
   */
  const invalidateQueries = () => {
    queryClient.invalidateQueries(["products"]);
  };

  return {
    productsQuery,
    invalidateQueries,
  };
};

export const useSalesQueries = (isSalesPage) => {
  const queryClient = useQueryClient();
  const salesQuery = useQuery(
    ["sales"],
    async () => {
      const sales = await api.getSales();
      sales.map((sale) => queryClient.setQueryData(["sale", sale.slug], sale));
      return sales;
    },
    {
      ...BASE_QUERY_SETTINGS,
      enabled: !!isSalesPage,
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["sales"]);
  };

  return {
    salesQuery,
    invalidateQueries,
  };
};

export const useSaleQueries = (saleSlug) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const saleQuery = useQuery(
    ["sale", saleSlug],
    async () => {
      const [sale] = await api.getSale(saleSlug);
      if (!sale) {
        redirectTo404(history);
        return null;
      }
      return sale;
    },
    {
      ...BASE_QUERY_SETTINGS,
      keepPreviousData: false,
      enabled: !!saleSlug,
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["sale"]);
  };

  return {
    saleQuery,
    invalidateQueries,
  };
};

export const useBrandQueries = (brandName) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const brandQuery = useQuery(
    ["brand", brandName],
    async () => {
      const brand = await api.getBrand(brandName);
      if (!brand) {
        redirectTo404(history);
        return null;
      }
      if (brand.seo) {
        store.slugToSEO[brandName] = brand.seo;
      }
      return brand;
    },
    {
      ...BASE_QUERY_SETTINGS,
      keepPreviousData: false,
      enabled: !!brandName,
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["brand"]);
  };

  return {
    brandQuery,
    invalidateQueries,
  };
};

export const useLandingQueries = (landingName) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const landingQuery = useQuery(
    ["landing", landingName],
    async () => {
      const landing = await api.getGeneratePage(landingName);

      if (!landing) {
        redirectTo404(history);
        return null;
      }

      if (landing.seo) {
        store.slugToSEO[landingName] = landing.seo;
      }
      return landing;
    },
    {
      ...BASE_QUERY_SETTINGS,
      keepPreviousData: false,
      enabled: !!landingName,
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["landing"]);
  };

  return {
    landingQuery,
    invalidateQueries,
  };
};

export const useCategoryDescriptionQueries = ({
  header,
  generateSlug,
  brandName,
}) => {
  const queryClient = useQueryClient();

  const categoryDescriptionQuery = useQuery(
    ["categoryDescription", header],
    async () => {
      const [categoryDescription] = await api.getCategoryDesc(header);
      return categoryDescription || null;
    },
    {
      ...BASE_QUERY_SETTINGS,
      keepPreviousData: false,
      enabled: !!header && !generateSlug && !brandName,
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["categoryDescription"]);
  };

  return {
    categoryDescriptionQuery,
    invalidateQueries,
  };
};
