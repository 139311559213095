import React from "react";
import api from "./jsCommon/api";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // Обновляем состояние, когда ошибка обнаружена
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // Логируем информацию об ошибке
  componentDidCatch(error, errorInfo) {
    console.error("Ошибка в компоненте:", error, errorInfo);
    api.postErrorInfo({
      error: error.message,
      errorInfo: errorInfo.componentStack,
      href: window.location.href,
    });
  }

  render() {
    if (this.state.hasError) {
      // Отображаем запасной UI при возникновении ошибки
      return (
        <div className="error-boundary">
          <h2>Что-то пошло не так.</h2>
          <p>
            Попробуйте обновить страницу или перейти на главную страницу.
            <br />
            <span
              style={{
                opacity: 0.3,
                fontSize: "0.8em",
              }}
            >
              Мы уже получили информацию об ошибке и скоро её исправим.
            </span>
          </p>

          <button
            className="btn btn_primary"
            onClick={() => (window.location.href = "/")}
          >
            Перейти на главную страницу
          </button>
        </div>
      );
    }

    // Если ошибки нет, рендерим дочерние компоненты
    return this.props.children;
  }
}

export default ErrorBoundary;
