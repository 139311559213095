import React, { useEffect, useState } from "react";

/**
 *
 * @param {number} count
 * @param {number} [page=1] page
 * @param {()=>void} changePage
 * @param {object} routeProps
 * @return {JSX.Element}
 * @constructor
 */
const Pagination = ({ count, page = 1, changePage, routeProps }) => {
  const maxPage = Math.ceil(count / 45);
  //count / (page * 45) > 1;
  const haveNextPage = maxPage !== page;
  const [parseUrl, setParseUrl] = useState([]);

  useEffect(() => {
    const { search, pathname } = routeProps.location;

    if (search) {
      if (search.includes(`page=${page}`)) {
        const newSearch = search.split(`page=${page}`);
        setParseUrl([
          "https://motorfirst.ru" + pathname + newSearch[0],
          newSearch[1],
        ]);
      } else {
        setParseUrl(["https://motorfirst.ru" + pathname + search, ""]);
      }
    } else {
      setParseUrl(["https://motorfirst.ru" + pathname + "?", ""]);
    }
  }, [routeProps.location.pathname, routeProps.location.search]);

  const det = <p style={{ cursor: "default" }}>···</p>;

  const urlGenerator = (pageLocal) => parseUrl.join(`page=${pageLocal}`);

  const pageGenerator = (pageLocal) => (
    <a
      className={`${pageLocal === page ? "active" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        changePage(pageLocal, "pagin");
      }}
      href={urlGenerator(pageLocal)}
      key={pageLocal}
    >
      {pageLocal}
    </a>
  );

  const pages = [];

  let middlePage = page;

  if (page <= 3) {
    middlePage = 3;
  } else if (page > maxPage - 2) {
    middlePage = maxPage - 2;
  }

  if (middlePage > maxPage || maxPage === 1) {
    middlePage = maxPage;
  }

  if (middlePage > 1) {
    if (middlePage - 1 > 1) {
      pages.push(middlePage - 1);
    }
    if (middlePage !== maxPage) pages.push(middlePage);
    if (middlePage + 1 < maxPage) {
      pages.push(middlePage + 1);
    }
  }

  return (
    <div className="catalog__bottom">
      {haveNextPage && (
        <button
          className="btn btn_secondary btn_full"
          onClick={() => changePage(page + 1, "btn")}
        >
          Показать еще
        </button>
      )}
      <div className="catalog__pagination card-pagination">
        {page > 1 && (
          <a onClick={() => changePage(page - 1, "pagin")}>
            <i className="i-pag-left" />
          </a>
        )}

        {pageGenerator(1)}
        {page > 3 && maxPage !== 4 && det}

        {pages
          .filter((page) => page !== 1 && page !== maxPage)
          .map((page) => pageGenerator(page))}

        {page <= maxPage - 3 && maxPage !== 4 && det}
        {maxPage !== 1 && pageGenerator(maxPage)}
        {haveNextPage && (
          <a onClick={() => changePage(page + 1, "pagin")}>
            <i className="i-pag-right" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Pagination;
