import React, { useEffect, useMemo, useState, useCallback } from "react";
import store from "../../../MobX";
import { parseEditorJsToDesc, sendNotFoundPath } from "src/funcs";
import { useHistory } from "react-router";
import { ulrParse } from "./useUrlParamsWithQueryParams";
import { Link } from "react-router-dom";

const HEADERS_BY_PAGE = {
  sale: "Скидки",
  new: "Новинки",
  "podarochnye-karty": "Подарочные карты",
  personal: "Ваша подборка",
};

/**
 * @param {Object} prop
 * @param {string} prop.razdel
 * @param {boolean} prop.sale
 * @param {[]} prop.personalUID
 * @param {Object} prop.landing
 * @param {Object} prop.brand
 * @returns {{pageType: 'catalog' | 'sale' | 'new' | 'personal' | 'podarochnye-karty' | 'landing' | 'brand'}}
 */
const getPageInfo = ({ razdel, sale, personalUID, landing, brand }) => {
  /** @type {'catalog' | 'sale' | 'new' | 'personal' | 'podarochnye-karty' | 'landing' | 'brand'} */
  let pageType = "catalog";

  if (razdel === "sale" || sale) pageType = "sale";
  else if (razdel === "new") pageType = "new";
  else if (personalUID && personalUID.length) pageType = "personal";
  else if (razdel === "podarochnye-karty") pageType = "podarochnye-karty";
  else if (landing) pageType = "landing";
  else if (brand) pageType = "brand";

  return {
    pageType,
  };
};

const checkCategoryes = ({ pageType, point, subrazvel, razdel, history }) => {
  const { catalogIsLoad, slugToNameCatalog } = store;
  if (!catalogIsLoad) return;

  if (pageType !== "catalog") return;

  if (
    (point && !slugToNameCatalog[point]) ||
    (subrazvel && !slugToNameCatalog[subrazvel]) ||
    (razdel && !slugToNameCatalog[razdel])
  ) {
    sendNotFoundPath(window.location.pathname);
    history.replace(`/404`);
  }
};

const getLastSlug = (point, subrazvel, razdel) => {
  if (point) return subrazvel + point;
  if (subrazvel) return razdel + subrazvel;
  return razdel;
};

const parseCategoryDescription = (description) => {
  if (!description) return null;
  const parsDesc = [];
  const parsDescSecond = [];
  const cont = document.createElement("div");
  cont.innerHTML = description;

  Array.from(cont.childNodes).forEach((el, i) => {
    const data = {};
    const nodeType = el.nodeName ? el.nodeName.toLowerCase() : "";

    switch (nodeType) {
      case "h2":
        data.text = el.textContent;
        data.header = true;
        if (i < 3) parsDesc.push(<h2 key={`h2-${i}`}>{el.textContent}</h2>);
        else parsDescSecond.push(<h2 key={`h2-${i}`}>{el.textContent}</h2>);
        break;
      case "p":
        data.text = el.textContent;
        data.paragraph = true;
        if (i < 3) parsDesc.push(<p key={`p-${i}`}>{el.textContent}</p>);
        else parsDescSecond.push(<p key={`p-${i}`}>{el.textContent}</p>);
        break;
      case "ul":
        data.text = [];
        const lis = [];

        Array.from(el.childNodes).forEach((li, liIndex) => {
          const liNodeType = li.nodeName ? li.nodeName.toLowerCase() : "";
          if (liNodeType === "li" && li.textContent) {
            const text = li.textContent;
            data.text.push(text);
            lis.push(<li key={`li-${liIndex}`}>{text}</li>);
          }
        });

        if (i < 3) parsDesc.push(<ul key={`ul-${i}`}>{lis}</ul>);
        else parsDescSecond.push(<ul key={`ul-${i}`}>{lis}</ul>);

        data.list = true;
        break;

      default:
        break;
    }
  });

  return [parsDesc, parsDescSecond];
};

const arrayToLandingInTop = (landing, isBrandPage) => {
  if (!landing || !Array.isArray(landing)) return [];

  const handleLandingClick = (landName) => {
    const landingEntryPoint =
      window.sessionStorage.getItem("landingEntryPoint");
    const landingEntryPointJS = landingEntryPoint
      ? JSON.parse(landingEntryPoint)
      : {};
    landingEntryPointJS[landName] = isBrandPage
      ? { from: "brand" }
      : { from: "category" };
    window.sessionStorage.setItem(
      "landingEntryPoint",
      JSON.stringify(landingEntryPointJS)
    );
  };

  return landing.map((land) => {
    return (
      <div className="catalog__head-list-item" key={land.slag}>
        <Link
          to={`/landing/${land.slag}`}
          className="catalog__head-list-link "
          onClick={() => handleLandingClick(land.imya)}
        >
          {land.imya}
        </Link>
      </div>
    );
  });
};

const arrayToCategInTop = (categs) => {
  if (!categs) return [];
  if (Array.isArray(categs)) {
    return categs
      .slice()
      .sort()
      .map((cat) => {
        return (
          <div className="catalog__head-list-item" key={cat}>
            <Link
              to={(loc) =>
                `${
                  loc.pathname.endsWith("/")
                    ? loc.pathname.slice(0, loc.pathname.length - 1)
                    : loc.pathname
                }/${store.nameToSlugCatalog[cat]}`
              }
              className="catalog__head-list-link"
            >
              {cat}
            </Link>
          </div>
        );
      });
  } else {
    return Object.keys(categs)
      .sort()
      .map((cat) => {
        return (
          <div className="catalog__head-list-item" key={cat}>
            <Link
              to={(loc) =>
                `${
                  loc.pathname.endsWith("/")
                    ? loc.pathname.slice(0, loc.pathname.length - 1)
                    : loc.pathname
                }/${cat}`
              }
              className="catalog__head-list-link"
            >
              {store.slugToNameCatalog[cat]}
            </Link>
          </div>
        );
      });
  }
};

export const useCatalog = ({
  point,
  subrazvel,
  razdel,
  sale,
  personalUID,
  searchString,
  count,

  saleData,
  landing,
  brand,
}) => {
  const history = useHistory();

  const {
    dataCatalog,
    catalogIsLoad,
    slugToNameCatalog,
    slugBrandToLanding,
    slugCatToLanding,
    slugToId,
    idCatToLanding,
  } = store;

  const pageInfo = useMemo(
    () => getPageInfo({ razdel, sale, personalUID, landing, brand }),
    [razdel, sale, personalUID, landing, brand]
  );

  const { pageType } = pageInfo;

  const header = useMemo(() => {
    if (!catalogIsLoad) return "Каталог";

    if (landing?.imya) return landing.imya;
    if (searchString) return `Найдено товаров: ${count}`;

    const defaultHeader = HEADERS_BY_PAGE[pageType] || "Каталог";

    const getTitile = () => {
      switch (pageType) {
        case "catalog":
          const category = point || subrazvel || razdel;

          return slugToNameCatalog[category];
        case "sale":
          return saleData?.imya;
        case "landing":
          return landing?.imya;
        case "brand":
          return brand?.nazvanie;
        default:
          return null;
      }
    };

    return getTitile() || defaultHeader;
  }, [
    catalogIsLoad,
    pageType,
    point,
    subrazvel,
    razdel,
    landing?.imya,
    saleData?.imya,
    brand?.nazvanie,
    count,
    searchString,
    slugToNameCatalog,
  ]);

  const landingOnTop = useMemo(() => {
    if (!catalogIsLoad) return [];
    if (searchString) return [];

    if (brand?.nazvanie) {
      const brandLandings = slugBrandToLanding[brand?.nazvanie];

      return arrayToLandingInTop([...(brandLandings || [])], true);
    }

    const name = point || subrazvel || razdel;
    if (slugCatToLanding[name])
      return arrayToLandingInTop([...slugCatToLanding[name]]);

    const categories = [point, subrazvel, razdel].filter(Boolean).join("");
    const pointId = slugToId[categories];
    const landings = idCatToLanding[pointId] || [];

    return arrayToLandingInTop(landings);
  }, [
    catalogIsLoad,
    point,
    subrazvel,
    razdel,
    slugCatToLanding,
    slugBrandToLanding?.[brand?.nazvanie],
    slugToId,
    idCatToLanding,
    searchString,
  ]);

  const categoriesOnTop = useMemo(() => {
    if (!catalogIsLoad) return [];
    if (searchString) return [];

    if (pageType !== "catalog" || point) return [];

    if (subrazvel) {
      if (dataCatalog?.[razdel]?.[subrazvel])
        return arrayToCategInTop(dataCatalog[razdel][subrazvel]);
    } else if (razdel) {
      if (dataCatalog?.[razdel]) return arrayToCategInTop(dataCatalog[razdel]);
    } else {
      return arrayToCategInTop(dataCatalog);
    }
  }, [
    catalogIsLoad,
    point,
    subrazvel,
    razdel,
    pageType,
    dataCatalog,
    searchString,
  ]);

  useEffect(() => {
    checkCategoryes({ pageType, point, subrazvel, razdel, history });
  }, [point, subrazvel, razdel, pageType]);

  return {
    header,
    categoriesOnTop,
    landingOnTop,
  };
};

export const useCatalogDescription = ({
  landing,
  brand,
  categoryDescriptionData,
  point,
  subrazvel,
  razdel,
}) => {
  const { catalogIsLoad } = store;

  const description = useMemo(() => {
    if (landing)
      return landing?.opisanie?.html
        ? parseEditorJsToDesc(landing?.opisanie?.html)
        : null;
    if (brand)
      return brand?.opisanie?.html
        ? parseEditorJsToDesc(brand?.opisanie?.html)
        : null;
    if (categoryDescriptionData) {
      if (categoryDescriptionData?.opisanieKategorii?.html?.length) {
        return parseEditorJsToDesc(
          categoryDescriptionData.opisanieKategorii.html
        );
      } else if (categoryDescriptionData?.opisanie) {
        return parseCategoryDescription(categoryDescriptionData.opisanie);
      }
    }

    const lastSlug = getLastSlug(point, subrazvel, razdel);

    return null;
  }, [
    catalogIsLoad,
    point,
    subrazvel,
    razdel,
    landing?.opisanie?.html,
    brand?.opisanie?.html,
  ]);

  return description;
};
