import React, { useEffect, useState } from "react";

const CallbackModal = ({ closeCallbackModal }) => {
  const [callbackEmail, setCallbackEmail] = useState(true);
  return (
    <div className="modal-window">
      <div className="modal-window__top">
        <p className="modal-window__title">Форма обратной связи</p>
        <button
          className="modal-window__close"
          onClick={() => {
            closeCallbackModal();
          }}
        >
          <i className="i-close" />
        </button>
      </div>
      <form className="modal-window__form">
        <label className="modal-window__label" for="callback-name">
          Имя
        </label>
        <input className="modal-window__input" id="callback-name" />
        <label className="modal-window__label" for="callback-question">
          Вопрос
        </label>
        <textarea className="modal-window__input" id="callback-question" />
        <p className="modal-window__answer">Ответить на:</p>

        <input
          className="modal-window__radio-input"
          type="radio"
          name="callback-type"
          id="radio-phone"
          onChange={() => {
            setCallbackEmail(false);
          }}
        />
        <label className="modal-window__radio-label" for="radio-phone">
          Телефон
        </label>
        <input
          className="modal-window__radio-input"
          type="radio"
          name="callback-type"
          id="radio-email"
          defaultChecked
          onChange={() => {
            setCallbackEmail(true);
          }}
        />
        <label className="modal-window__radio-label" for="radio-email">
          E-mail
        </label>
        {callbackEmail ? (
          <>
            <label className="modal-window__label" for="callback-email">
              E-mail
            </label>
            <input
              className="modal-window__input"
              id="callback-email"
              type="email"
            />
          </>
        ) : (
          <>
            <label className="modal-window__label" for="callback-phone">
              Телефон
            </label>
            <input
              className="modal-window__input"
              id="callback-phone"
              type="number"
            />
          </>
        )}

        <button className="modal-window__btn btn btn_primary" type="submit">
          Отправить
        </button>
        <input
          type="checkbox"
          id="callback-access"
          className="modal-window__checkbox-input"
        />
        <label for="callback-access">
          Я принимаю{" "}
          <a href="/service/personal-data">
            "Cоглашение об обработке персональных данных"
          </a>
        </label>
      </form>
    </div>
  );
};

export default CallbackModal;
